@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
.digit {
  width: 100%;

  &__tab {
    color: rgba(138, 138, 138, 1);
    background: rgba(249, 249, 249, 1);
    .flex-type(space-between);

    &__item {
      flex-shrink: 1;
      text-align: center;
      min-width: fit-content;
      .set-size(100%);

      &--active {
        font-weight: 700;
        font-size: 0.28rem;
        color: @primary-color;
      }
    }

    &--transparent {
      color: @white-color !important;
      background: transparent;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(0.08rem);
        content: '';
        .set-size(100%);
      }

      &--active {
        font-weight: 700;
        font-size: 0.28rem;
        color: @primary-color;
      }
    }
  }
}

.banner {
  position: relative;
  z-index: 0;
  .set-size(100%, 5.62rem);
  .flex-type(flex-start, column);

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    .set-size(100%, 5.62rem);
  }

  &__container {
    margin-top: 0.78rem;
    .set-size(6.42rem, 3.64rem);
    .flex-type(center, column);
  }

  &__logo {
    .set-size(4.64rem, 0.98rem);
  }

  &__content {
    width: 6.42rem;
    margin-top: 0.74rem;
    font-size: 0.24rem;
    line-height: 0.48rem;
    color: @text-color;
  }
}

@primary-color: rgba(204, 140, 75, 1);