@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.merchant {
  width: 100%;
  padding: 1.04rem 0 1.28rem;
  background-color: @white-color;
  .flex-type(center, column);

  img {
    max-width: 6.42rem;
  }

  &__article {
    width: 100%;
    padding: 0 0.32rem 1.28rem;
    border-bottom: 0.16rem solid rgba(249, 249, 249, 1);
    .flex-type(center, column);

    &__logo {
      margin-bottom: 1.04rem;
      .set-size(4.64rem, 1.46rem);
    }

    &__content {
      width: 6.42rem;
      font-size: 0.28rem;
      line-height: 0.6rem;
      color: @text-color;
    }

    &__scene {
      margin: 0.5rem 0;
      .set-size(6.42rem, 2.71rem);
    }

    &__split {
      margin: 0.8rem 0 0.94rem 0;
      .set-size(0.36rem);
    }
  }

  &__button {
    z-index: 10;
    .set-size(100%, 1.2rem);
    .flex-type(center);

    &--fixed {
      position: fixed;
      bottom: 0;
      left: 0;
    }

    &--absolute {
      position: absolute;
      bottom: 0.4rem;
      left: 0;
    }

    &__ins {
      background: linear-gradient(90deg, rgba(232, 169, 89, 1) 0%, rgba(127, 65, 32, 1) 100%);
      border-radius: 0.5rem;
      font-size: 0.4rem;
      font-weight: 700;
      color: @white-color;
      line-height: 0.4rem;
      .set-size(6.86rem, 0.88rem);
    }
  }
}

.book {
  &__img {
    .set-size(5.36rem, 4.28rem);
  }

  &__label {
    width: 100%;
    padding: 0.56rem 0 0.32rem 0;
    margin-bottom: 0.2rem;
    border-bottom: 1px solid @primary-color;
    text-align: center;
    font-size: 0.36rem;
    font-weight: 700;
    line-height: 0.5rem;
    color: @primary-color;
  }

  &__person {
    width: 100%;
    font-size: 0.28rem;
    line-height: 0.6rem;
    color: @text-color;

    &__item {
      margin-bottom: 0.32rem;
    }

    &__name {
      width: 1rem;
      flex-shrink: 0;
      margin-right: 0.1rem;
      font-size: 0.32rem;
      font-weight: 700;
    }
  }
}

.interview {
  width: 100%;
  padding: 0 0.32rem;
  margin: 0.64rem 0 0.8rem 0;
  .flex-type(center, column);

  &__title {
    width: 100%;
    margin-bottom: 0.52rem;
    font-size: 0.36rem;
    font-weight: 700;
    color: @text-color;
    line-height: 0.5rem;
  }

  &__list {
    &__item {
      margin-bottom: 0.5rem;
    }
  }

  &__more {
    box-sizing: border-box;
    padding-left: 0.2rem;
    border-radius: 0.33rem;
    border: 1px solid @text-color-tertiary;
    font-size: 0.24rem;
    color: @text-color-tertiary;
    line-height: 0.56rem;
    .set-size(1.8rem, 0.66rem);
    .flex-type(center);
  }
}

@primary-color: rgba(204, 140, 75, 1);