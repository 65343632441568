@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.local {
  box-sizing: border-box;
  padding: 0.12rem 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    width: 12rem;
    background: @white-color;
    padding-bottom: 0.5rem;
    .flex-type(center, column);
  }
}

.title {
  margin-bottom: 0.4rem;
  font-size: 0.32rem;
  font-weight: 700;
  text-align: center;
  color: @text-color;
  line-height: 0.45rem;
}

.subtitle {
  margin-bottom: 0.4rem;
  font-size: 0.24rem;
  font-weight: 700;
  text-align: center;
  color: @text-color;
  line-height: 0.33rem;
}

.content {
  width: 8.45rem;
  font-size: 0.16rem;
  color: @text-color;
  line-height: 0.3rem;
}

.profile {
  width: 8.45rem;
  margin-top: 0.94rem;
}

.case {
  margin-top: 0.72rem;

  &__item {
    margin-bottom: 0.72rem;
  }

  &__content {
    margin-top: 0.24rem;
  }

  &__img {
    max-width: 8.45rem;
  }
}

@primary-color: rgba(204, 140, 75, 1);