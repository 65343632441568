@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.industry {
  box-sizing: border-box;
  padding: 1rem 0.54rem 2rem;
  .set-size(100%);
  .flex-type(center, column);

  img {
    max-width: 6.42rem;
  }

  &__tab {
    box-sizing: border-box;
    width: 100vw;
    margin-bottom: 0.5rem;

    &__ins {
      z-index: 2000;
    }

    &__item {
      position: relative;
      min-width: fit-content;
      flex-shrink: 1;
      text-align: center;
      font-size: 0.24rem;
      color: rgba(138, 138, 138, 1);
      .set-size(3.43rem, 100%);

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        border-radius: 0.03rem;
        opacity: 0.43;
        backdrop-filter: blur(8px);
        background-color: rgba(217, 217, 217, 1);
        content: '';
        .set-size(100%, 0.06rem);
      }
    }

    &__active {
      font-size: 0.28rem;
      font-weight: 700;
      color: @primary-color;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        opacity: 1;
        border-radius: 0.03rem;
        backdrop-filter: blur(8px);
        background-color: @primary-color;
        content: '';
        .set-size(100%, 0.06rem);
      }
    }
  }

  &__button {
    z-index: 2001;
    .set-size(7.5rem, 1.2rem);
    .flex-type(center);

    &--fixed {
      position: fixed;
      bottom: 0;
      left: 0;
    }

    &--absolute {
      position: absolute;
      bottom: 0.4rem;
      left: 0;
    }

    &__ins {
      background: linear-gradient(90deg, rgba(232, 169, 89, 1) 0%, rgba(127, 65, 32, 1) 100%);
      border-radius: 0.5rem;
      font-size: 0.4rem;
      font-weight: 700;
      color: @white-color;
      .set-size(6.86rem, 0.88rem);
    }
  }
}

.profile {
  width: 100%;
  .flex-type(center, column);

  &__logo {
    .set-size(5.72rem, 1rem);
  }

  &__img {
    margin-top: 0.96rem;
    .set-size(6.42rem, 4.2rem);
  }

  &__content {
    width: 100%;
    margin: 0.48rem 0;
    font-size: 0.28rem;
    color: @text-color;
    line-height: 0.56rem;
  }

  &__cooperator {
    margin-bottom: 0.32rem;
    .set-size(6.3rem, 4.5rem);
  }
}

.structure {
  width: 100%;
  .flex-type(center, column);

  &__item {
    display: flex;
    width: 100%;
    min-width: 6.6rem;
    min-height: 1.8rem;

    &:not(:last-of-type) {
      margin-bottom: 0.78rem;
    }

    &__avatar {
      margin-right: 0.48rem;
      border-radius: 50%;
      .set-size(1.8rem);
    }

    &__info {
      margin-top: 0.1rem;
      .flex-type(flex-start, column);

      &__emphasis {
        width: 100%;
        display: flex;
      }
    }

    &__rank {
      font-size: 0.24rem;
      color: @primary-color;
      line-height: 0.34rem;
    }

    &__name {
      margin-left: 0.3rem;
      font-size: 0.36rem;
      font-weight: 700;
      color: @primary-color;
      line-height: 0.24rem;
    }

    &__appellation {
      margin-top: 0.16rem;
      font-size: 0.24rem;
      color: @text-color;
      line-height: 0.44rem;
    }
  }
}

.event {
  width: 100%;
  .flex-type(flex-start, column);

  &__item {
    position: relative;
    padding-bottom: 0.48rem;

    &::after {
      position: absolute;
      bottom: 0;
      left: -0.22rem;
      z-index: 0;
      background-color: rgba(230, 231, 232, 1);
      content: '';
      .set-size(6.86rem, 0.02rem);
    }

    &:not(:last-child) {
      margin-bottom: 0.38rem;
    }

    &__title {
      margin: 0.48rem 0;
      font-size: 0.36rem;
      font-weight: 700;
      color: @text-color;
      line-height: 0.5rem;
      .ellipsis-multiple(2);
    }

    &__content {
      width: 100%;
      font-size: 0.28rem;
      color: @text-color;
      line-height: 0.56rem;
    }
  }
}

.apply {
  flex-wrap: wrap;
  width: 5rem;
  padding: 0.48rem;
  .flex-type();

  &__item {
    font-size: 0.28rem;
    color: @text-color;
    line-height: 0.4rem;
    .flex-type(center, column);
    .set-size(1.2rem, 1.78rem);

    &:nth-of-type(2n) {
      margin-left: 1.6rem;
    }

    &:nth-of-type(n + 3) {
      margin-top: 0.48rem;
    }
  }

  &__img {
    position: relative;
    margin-bottom: 0.18rem;
    .set-size(1.2rem);

    &__default {
      position: absolute;
      top: 0;
      left: 0;
      .set-size(100%);
    }

    &__active {
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.4s all;
      .set-size(100%);
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);