@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.introduction {
  box-sizing: border-box;
  padding-bottom: 1rem;
  .set-size(100%);
  .flex-type(center, column);

  img {
    max-width: 100vw;
  }

  &__img {
    margin: 0.8rem 0;
    width: 6.41rem;
  }

  &__content {
    width: 6.42rem;
    font-size: 0.28rem;
    color: @text-color;
    line-height: 0.56rem;
  }
}

.logo {
  position: relative;
  width: 100%;
  margin-top: 1.1rem;
  z-index: 0;
  .flex-type(center);

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 1.75rem;
  }

  &__icon {
    .set-size(2.36rem);
  }
}

.list {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0.54rem;
  margin-top: 1.48rem;

  &__label {
    font-size: 0.36rem;
    font-weight: 700;
    color: @primary-color;
    line-height: 0.64rem;

    &:nth-child(n + 2) {
      margin-top: 0.64rem;
    }
  }

  &__content {
    font-size: 0.28rem;
    color: @text-color;
    line-height: 0.64rem;
    // 解析换行
    white-space: pre-wrap;
  }
}

@primary-color: rgba(204, 140, 75, 1);