@import '../../assets/styles/abstracts/mixins.less';
@import '../../assets/styles/abstracts/function.less';
@import '../../assets/styles/abstracts/variables.less';
@import '../../assets/styles/abstracts/reset.less';
@import '../../assets/styles/abstracts/global.less';
.swiper {
  position: relative;
  .flex-type();

  &__arrow {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    .set-size(0.65rem);
    .flex-type(center);

    &:hover {
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);
    }

    & > img {
      .set-size(0.18rem, 0.41rem);
    }

    &__left {
      left: -1rem;

      & > img {
        margin-right: 0.06rem;
      }
    }

    &__right {
      right: -1rem;

      & > img {
        margin-left: 0.06rem;
      }
    }

    &--shadow {
      &:hover {
        box-shadow: 0 0.065rem 0.11rem 0 rgba(251, 230, 197, 1);
      }
    }
  }

  &__ins {
    width: 100%;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .set-size(100%, 0.3rem);

    &__item {
      cursor: pointer;
      background-color: rgba(216, 216, 216, 1);
      .set-size(0.3rem, 0.04rem);

      &:not(:last-of-type) {
        margin-right: 0.16rem;
      }

      &--active {
        cursor: default;
        background-color: @primary-color;
        .set-size(0.6rem, 0.04rem);
      }
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);