@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.application {
  min-height: calc(100vh - 1.08rem);
  padding: 0.48rem 0.32rem;
  background-color: rgba(240, 242, 245, 1);
  .set-size(100%);
}

.step {
  width: 100%;

  :global {
    .ant-steps-item-title {
      font-size: 0.24rem;
      color: rgba(138, 138, 138, 1) !important;
      line-height: 0.24rem;
    }
  }
}

.form {
  width: 6.86rem;
  margin-top: 0.6rem;
  .flex-type(center, column);

  :global {
    .ant-form {
      width: 100%;
    }
  }
}

.button {
  &__box {
    box-sizing: border-box;
    padding: 0 0.32rem;
    margin-top: 1rem;
    .flex-type(center);
    .set-size(100%, 1.2rem);
  }

  &__item {
    flex-shrink: 1;
    background: linear-gradient(90deg, rgba(232, 169, 89, 1) 0%, rgba(127, 65, 32, 1) 100%);
    border-radius: 0.5rem;
    font-size: 0.4rem;
    color: @white-color;
    .set-size(100%, 0.88rem);

    &:not(:last-child) {
      margin-right: 0.25rem;
    }
  }
}

:global {
  .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: @primary-color !important;
  }
}

@primary-color: rgba(204, 140, 75, 1);