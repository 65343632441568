@import '../../assets/styles/abstracts/mixins.less';
@import '../../assets/styles/abstracts/function.less';
@import '../../assets/styles/abstracts/variables.less';
@import '../../assets/styles/abstracts/reset.less';
@import '../../assets/styles/abstracts/global.less';
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
  .set-size(100%);
  .flex-type(center);

  &__container--pc {
    position: relative;
    box-sizing: border-box;
    padding: 0.23rem;
    z-index: 10000;
    background: @white-color;
    border-radius: 0.08rem;
    .flex-type(center, column);

    .modal__icon {
      margin-bottom: 0.18rem;
      .set-size(0.44rem);
    }

    .modal__title {
      margin-bottom: 0.23rem;
      font-size: 0.18rem;
      font-weight: 700;
      color: @text-color;
      line-height: 0.25rem;
    }

    .modal__content {
      width: 2.79rem;
      font-size: 0.14rem;
      text-align: center;
      color: @text-color;
      line-height: 0.24rem;
    }

    .modal__children {
      font-size: 0.14rem;
      text-align: center;
      color: @text-color;
      line-height: 0.24rem;
    }

    .modal__button {
      width: 100%;
      margin-top: 0.18rem;
      .flex-type(center);

      &__item {
        flex-shrink: 1;
        background: linear-gradient(90deg, rgba(232, 169, 89, 1) 0%, rgba(127, 65, 32, 1) 100%);
        border-radius: 0.23rem;
        font-size: 0.16rem;
        color: @white-color;
        .set-size(50%, 0.4rem);
      }
    }

    .modal__cancel-icon {
      position: absolute;
      bottom: -0.74rem;
      .set-size(0.48rem);
    }
  }

  &__container--md {
    position: relative;
    box-sizing: border-box;
    padding: 0.66rem 0.42rem 0.44rem;
    z-index: 10000;
    background: @white-color;
    border-radius: 0.16rem;
    .flex-type(center, column);

    .modal__icon {
      margin-bottom: 0.34rem;
      .set-size(0.88rem);
    }

    .modal__title {
      margin-bottom: 0.3rem;
      font-size: 0.36rem;
      font-weight: 700;
      color: @text-color;
      line-height: 0.5rem;
    }

    .modal__content {
      width: 5.58rem;
      font-size: 0.28rem;
      text-align: center;
      color: @text-color;
      line-height: 0.48rem;
    }

    .modal__children {
      font-size: 0.28rem;
      text-align: center;
      color: @text-color;
      line-height: 0.48rem;
    }

    .modal__button {
      width: 100%;
      margin-top: 0.38rem;
      .flex-type(center);

      &__item {
        flex-shrink: 1;
        background: linear-gradient(90deg, rgba(232, 169, 89, 1) 0%, rgba(127, 65, 32, 1) 100%);
        border-radius: 0.46rem;
        font-size: 0.36rem;
        font-weight: 700;
        color: @white-color;
        .set-size(50%, 0.88rem);
      }
    }

    .modal__cancel-icon {
      position: absolute;
      bottom: -1.16rem;
      .set-size(0.8rem);
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);