@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
.news {
  padding-bottom: 0.5rem;
  .set-size(100%);
  .flex-type(center, column);

  &__tab {
    position: sticky;
    left: 0;
    top: 0.86rem;
    z-index: 10;
    background: rgba(240, 242, 245, 0.68);
    backdrop-filter: blur(0.08rem);
  }

  &__container {
    padding: 0.5rem 0;
    background: @white-color;
    .set-size(12.5rem, 100%);
    .flex-type(center, column);
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  padding: 0.2rem;
  .set-size(12.5rem, 7.34rem);

  &__item {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(5) {
      margin-right: 0.58rem;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-bottom: 0.6rem;
    }
  }
}

.affair {
  width: 10rem;
  margin-top: 0.4rem;

  &__title {
    margin-bottom: 0.52rem;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: @text-color;
    line-height: 0.45rem;
  }

  &__list {
    &__item {
      &:not(:last-child) {
        margin-bottom: 0.3rem;
      }
    }
  }
}

.pagination {
  margin-top: 0.6rem;
}

@primary-color: rgba(204, 140, 75, 1);