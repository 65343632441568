@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
.institution {
  box-sizing: border-box;
  padding: 0.48rem 0.32rem 1rem;
  .set-size(100%);
  .flex-type(center, column);
}

@primary-color: rgba(204, 140, 75, 1);