@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.organization {
  box-sizing: border-box;
  padding: 0 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    width: 12rem;
    background: @white-color;
    padding: 0 1.77rem 0.86rem;
    .flex-type(center, column);

    img {
      max-width: 8.45rem;
    }
  }
}

.title {
  margin-bottom: 0.38rem;
  font-size: 0.32rem;
  font-weight: 700;
  color: @text-color;
  line-height: 0.45rem;
}

.portion {
  margin-top: 0.72rem;
  .flex-type(center, column);
}

.president {
  margin-top: 0.98rem;
  .flex-type(center, column);

  &__item {
    display: flex;
    width: 8.45rem;

    &:nth-child(n + 2) {
      margin-bottom: 0.5rem;
    }

    &__avatar {
      margin-right: 0.4rem;
      .set-size(1.2rem);
    }

    &__name {
      font-size: 0.25rem;
      font-weight: 700;
      color: @primary-color;
      line-height: 0.36rem;
    }

    &__rank {
      font-size: 0.16rem;
      color: @primary-color;
      line-height: 0.36rem;
    }

    &__profile {
      margin-top: 0.23rem;
      font-size: 0.14rem;
      color: @text-color;
      line-height: 0.3rem;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);