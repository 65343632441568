@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.application {
  padding: 0.12rem 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center);

  &__container {
    position: relative;
    box-sizing: border-box;
    padding: 0.38rem;
    background: @white-color;
    .set-size(12rem, 7.04rem);
    .flex-type(flex-start, column);
  }
}

.step {
  width: 7.96rem;
}

.form {
  width: 5.24rem;
  margin-top: 0.84rem;
  .flex-type(center, column);

  :global {
    .ant-form {
      width: 100%;
    }
  }
}

.button {
  &__box {
    position: absolute;
    left: 50%;
    bottom: 1.42rem;
    width: 5rem;
    transform: translateX(-50%);
    .flex-type(center);
  }

  &__item {
    flex-shrink: 1;
    background: linear-gradient(90deg, rgba(232, 169, 89, 1) 0%, rgba(127, 65, 32, 1) 100%);
    border-radius: 0.23rem;
    font-size: 0.18rem;
    color: @white-color;
    line-height: 0.25rem;
    .set-size(4.44rem, 0.45rem);

    &:not(:last-child) {
      margin-right: 0.25rem;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);