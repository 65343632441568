@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.chain {
  box-sizing: border-box;
  padding: 0.76rem 0.54rem 0.48rem;
  .set-size(100%);
  .flex-type(center, column);
}

.text {
  font-size: 0.28rem;
  line-height: 0.56rem;
  color: @text-color;
}

.profile {
  .flex-type(center, column);

  &__logo {
    margin-bottom: 0.54rem;
    .set-size(5.03rem, 1.58rem);
  }
}

.content {
  width: 100%;
  margin-top: 0.48rem;
  .flex-type(center, column);

  &__photo {
    max-width: 6.41rem !important;
    .set-size(100%, 1.87rem);
  }

  &__cooperator {
    max-width: 6.41rem !important;
    margin: 0.48rem 0;
    .set-size(100%, 0.9rem);
  }

  &__images {
    width: 100%;
    margin: 0.48rem 0;

    &__item {
      max-width: 6.42rem !important;

      &:not(:last-child) {
        margin-bottom: 0.12rem;
      }
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);