@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.summit {
  position: relative;
  padding-bottom: 1.73rem;
  z-index: 1000;
  background: rgba(0, 0, 0, 1);

  &__bg {
    position: absolute;
    left: 0;
    top: -1.08rem;
    z-index: -1;
    background: left top / 100% 7.1rem no-repeat
        url(https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/mobile/summit-bg-1.png),
      left 5.5rem / 100% 8.76rem no-repeat
        url(https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/mobile/summit-bg-2.png);
    .set-size(100%);
  }

  &__content {
    z-index: 1;
    padding-top: 6.02rem;
    .flex-type(center, column);
  }
}

.subtitle {
  z-index: 1;
  font-size: 0.36rem;
  font-weight: 700;
  text-align: center;
  color: @primary-color;
  line-height: 0.5rem;
}

.brief {
  z-index: 1;
  .flex-type(center, column);

  &__logo {
    margin: 0.68rem 0 0.62rem 0;
    .set-size(4.58rem, 0.64rem);
  }

  &__text {
    width: 6.42rem;
    font-size: 0.28rem;
    line-height: 0.56rem;
    color: @white-color;
  }
}

.scene {
  z-index: 1;
  padding: 0 0.32rem;
  margin-top: 0.68rem;

  &__small {
    display: flex;

    &__ins {
      .set-size(3.36rem, 2.24rem);

      img {
        .set-size(3.36rem, 2.24rem);
      }

      &:last-of-type {
        margin-left: 0.14rem;
      }
    }
  }

  &__large {
    .set-size(6.86rem, 4.31rem);
    margin: 0.14rem 0;

    img {
      .set-size(6.86rem, 4.31rem);
    }
  }
}

.winner {
  z-index: 1;
  width: 100%;
  margin-top: 1.02rem;
  padding: 0 0.14rem;

  &__tab {
    box-sizing: border-box;
    width: 100%;
    padding: 0 0.18rem;
    margin-bottom: 0.44rem;

    &__ins {
      box-sizing: border-box;
      background: transparent;
      color: rgba(138, 138, 138, 1);
      font-size: 0.24rem;
      .set-size(6.9rem, 1rem);
      .flex-type();
    }

    &__item {
      position: relative;
      flex-shrink: 1;
      min-width: fit-content;
      text-align: center;
      font-weight: 700;
      line-height: 1rem;
      .set-size(100%);

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(217, 217, 217, 1);
        border-radius: 0.03rem;
        backdrop-filter: blur(0.08rem);
        content: '';
        .set-size(100%, 0.06rem);
      }
    }

    &__active {
      position: relative;
      color: @white-color;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        background-color: @primary-color;
        border-radius: 0.03rem;
        backdrop-filter: blur(0.08rem);
        content: '';
        .set-size(100%, 0.06rem);
      }
    }
  }

  &__list {
    width: 100%;
    flex-wrap: wrap;
    .flex-type(center);

    &--2023 {
      .winner__list__item {
        margin-right: 0.88rem;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:nth-child(4n) {
          margin-right: 0.88rem;
        }
      }
    }

    &__item {
      margin: 0 0.14rem 0.14rem 0;
      .set-size(1.7rem, 3.98rem);

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}

.process {
  position: relative;
  z-index: 1;
  margin-top: 0.68rem;

  & &__bg {
    position: absolute;
    top: -0.8rem;
    left: -0.32rem;
    z-index: 1;
    max-width: 100vw;
  }

  & > &__img {
    max-width: 6.86rem;
    margin-top: 0.3rem;
  }

  &__text {
    width: 6.1rem;
    margin-top: 0.3rem;
    font-size: 0.28rem;
    line-height: 0.56rem;
    color: @white-color;
  }
}

.back {
  z-index: 1;
  padding: 0 0.32rem;
  margin-top: 0.88rem;

  & > div:nth-child(1) {
    margin-bottom: 0.68rem;
  }

  &__item {
    position: relative;
    display: flex;
    .set-size(100%, 2.8rem);

    &:not(:last-child) {
      margin-bottom: 0.1rem;
    }

    &__text {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      font-family: PingFangSC-Regular, PingFang SC;
      font-size: 1.2rem;
      font-weight: 200;
      color: rgba(249, 249, 249, 1);
      line-height: 1rem;
      .set-size(1.44rem, 2rem);
    }

    &__img {
      .set-size(6.86rem, 2.8rem);
    }
  }
}

.cooperator {
  z-index: 1;
  padding: 0 0.32rem;
  margin-top: 0.84rem;

  &__img {
    margin-top: 0.34rem;
    .set-size(6.86rem, 5.44rem);

    & > img {
      .set-size(6.86rem, 5.44rem);
    }
  }
}

.btn {
  z-index: 10;
  .set-size(100%, 1.2rem);
  .flex-type(center);

  &--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
  }

  &--absolute {
    position: absolute;
    bottom: 0.28rem;
    left: 0;
  }

  &__ins {
    color: @white-color;
    border-radius: 0.5rem;
    font-size: 0.4rem;
    font-weight: 700;
    line-height: 0.4rem;
    background: linear-gradient(90deg, rgb(232, 169, 89, 1) 0%, rgb(127, 65, 32, 1) 100%);
    .set-size(6.86rem, 0.88rem);
  }
}

@primary-color: rgba(204, 140, 75, 1);