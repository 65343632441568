@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.home {
  padding-bottom: 0.8rem;

  &__container {
    // padding: 0 0.32rem;
    margin-top: 1.16rem;
  }
}

.banner {
  box-sizing: border-box;
  .set-size(100%, 3.8rem);
  .flex-type(center);

  &__swiper {
    align-items: flex-start;
    .set-size(100%, 3.8rem);
  }

  &__img {
    object-fit: cover;
    .set-size(100%, 3.8rem);
  }
}

.title {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0.32rem;
  margin-bottom: 0.48rem;
  font-size: 0.36rem;
  font-weight: 700;
  color: @text-color;
  line-height: 0.5rem;
}

.more {
  margin-top: 0.48rem;
  font-size: 0.24rem;
  color: @text-color-tertiary;
  .flex-type(center);

  &__instance {
    box-sizing: border-box;
    padding-left: 0.18rem;
    border-radius: 0.33rem;
    border: 1px solid @text-color-tertiary;
    .set-size(1.8rem, 0.66rem);
    .flex-type(center);
  }
}

.project {
  .flex-type(center, column);

  &__container {
    .set-size(100%, 9.24rem);
    .flex-type();
  }

  &__swiper__ins {
    box-sizing: border-box;
    padding: 0 0.32rem;
    .set-size(100%);
  }

  &__card {
    position: relative;
    background-color: rgba(249, 249, 249, 1);
    border-radius: 0.16rem;
    transition: 0.3s all;
    transform: scale(0.94);
    .set-size(5.86rem, 8.74rem);

    &--active {
      box-shadow: 0 0.22rem 0.32rem 0 rgba(204, 140, 75, 0.16);
      transform: scale(1);
    }

    &__img {
      .set-size(100%, 3.26rem);
    }

    &__info {
      padding: 0 0.32rem;

      &__title {
        font-size: 0.32rem;
        font-weight: 700;
        color: @text-color;
        line-height: 0.84rem;
      }

      &__content {
        margin-top: 0.14rem;
        font-size: 0.28rem;
        color: @text-color;
        line-height: 0.56rem;
      }
    }

    &__icon {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.interview {
  margin-top: 0.4rem;
  .flex-type(center, column);

  &__container {
    box-sizing: border-box;
    width: 100%;
    padding: 0 0.32rem;
  }

  &__line {
    margin-top: 0.5rem;

    &__item:not(:last-of-type) {
      margin-bottom: 0.34rem;
    }
  }
}

.news {
  margin-top: 0.8rem;
  .flex-type(center, column);

  &__box {
    box-sizing: border-box;
    width: 100%;
    padding: 0 0.32rem;

    &__item {
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 0.3rem;
      }
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);