@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.about {
  width: 100%;

  &__tab {
    position: sticky;
    left: 0;
    top: 0.86rem;
    z-index: 10;
    background: rgba(240, 242, 245, 0.68);
    backdrop-filter: blur(0.08rem);
  }
}

@primary-color: rgba(204, 140, 75, 1);