@import '../../assets/styles/abstracts/mixins.less';
@import '../../assets/styles/abstracts/function.less';
@import '../../assets/styles/abstracts/variables.less';
@import '../../assets/styles/abstracts/reset.less';
@import '../../assets/styles/abstracts/global.less';
.affair {
  &__time {
    position: relative;
    flex-shrink: 0;
    font-family: PingFangSC-Medium, PingFang SC;
    color: @primary-color;
  }

  &__title {
    font-family: PingFangSC-Medium, PingFang SC;
    color: @text-color;
    .ellipsis-multiple(2);
  }

  &--pc {
    width: 100%;
    cursor: pointer;
    .flex-type(flex-start);

    .affair__time {
      width: 0.9rem;
      line-height: 0.36rem;

      &::after {
        position: absolute;
        right: -0.02rem;
        top: 0.02rem;
        background-color: rgba(217, 217, 217, 1);
        content: '';
        .set-size(0.01rem, 0.63rem);
      }

      &__date {
        font-size: 0.28rem;
      }

      &__month {
        font-size: 0.18rem;
      }
    }

    .affair__title {
      padding-left: 0.18rem;
      font-size: 0.16rem;
    }
  }

  &--mobile {
    width: 100%;
    padding: 0.4rem 0;
    border-top: 1px solid rgba(230, 231, 232, 1);
    border-bottom: 1px solid rgba(230, 231, 232, 1);
    .flex-type(flex-start);

    .affair__time {
      width: 1.72rem;
      line-height: 0.64rem;

      &::after {
        position: absolute;
        right: 0;
        top: 0.02rem;
        background-color: rgba(217, 217, 217, 1);
        content: '';
        .set-size(0.02rem, 1.26rem);
      }

      &__date {
        font-size: 0.56rem;
      }

      &__month {
        font-size: 0.36rem;
      }
    }

    .affair__title {
      padding-left: 0.34rem;
      font-size: 0.32rem;
      .set-size(4.8rem, 0.96rem);
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);