@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
@line-color: rgba(207, 156, 110, 1);

.line {
  display: flex;
  background: @white-color;
  .set-size(6.85rem, 1.47rem);

  .line__number {
    position: relative;
    width: fit-content;
    padding-right: 0.34rem;
    font-size: 0.36rem;
    color: @line-color;
    line-height: 0.56rem;

    &::after {
      position: absolute;
      top: 0.12rem;
      right: 0;
      width: 0.02rem;
      height: 0.86rem;
      background-color: @line-color;
      content: '';
    }
  }

  .line__title {
    box-sizing: border-box;
    padding-left: 0.34rem;
    font-size: 0.32rem;
    font-weight: 700;
    color: @text-color;
    line-height: 0.52rem;
    border-bottom: 1px solid rgba(229, 229, 229, 1);
    .set-size(5.58rem, 1.33rem);
    .ellipsis-multiple(2);
  }
}

@primary-color: rgba(204, 140, 75, 1);