@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.footer {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.16rem 0 0.26rem 0;
  background: rgba(51, 51, 51, 1);
  .set-size(100%, 2.28rem);
  .flex-type(center);

  &__content {
    display: flex;
    font-size: 0.12rem;
    color: rgba(167, 167, 167, 1);

    & a{
      font-size: 0.12rem;
      color: rgba(167, 167, 167, 1);
    }
  }

  &__info:not(:last-child) {
    margin-right: 0.46rem;
  }

  &__label {
    margin-bottom: 0.04rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 700;
    line-height: 0.42rem;
  }

  &__info {
    &__line {
      display: flex;
      line-height: 0.28rem;

      & > div:nth-child(1) {
        flex-shrink: 0;
        width: 0.5rem;
      }
    }

    &__project {
      cursor: pointer;

      &:hover {
        color: @white-color;
      }
    }
  }

  &__code {
    margin: 0.72rem 0.42rem 0 0;
    .flex-type(flex-start, column);

    &__img {
      margin-bottom: 0.06rem;
      .set-size(0.8rem);
    }
  }

  &__beian {
    &__img {
      margin-bottom: 0.06rem;
      margin-left: 0.1rem;
      margin-right: 0.04rem;
      .set-size(0.16rem,0.17rem);
    }
  }

  &__right {
    margin: 1.58rem 0 0 0.47rem;
  }
}

@primary-color: rgba(204, 140, 75, 1);