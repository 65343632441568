@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.standard {
  box-sizing: border-box;
  padding: 0.12rem 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    width: 12rem;
    background: @white-color;
    padding: 0 1.77rem 0.5rem;
    .flex-type(center, column);

    img {
      max-width: 8.45rem;
    }
  }
}

.title {
  margin-bottom: 0.4rem;
  font-size: 0.32rem;
  font-weight: 700;
  text-align: center;
  color: @text-color;
  line-height: 0.45rem;
}

.dues {
  margin-top: 0.92rem;
  width: 8.45rem;

  &__table {
    border-top: 1px solid @primary-color;
    border-bottom: 1px solid @primary-color;

    &__line {
      padding: 0.28rem 0.22rem;
      .flex-type();

      &:last-child {
        border-top: 1px solid @primary-color;
      }

      &__type {
        position: relative;
        width: 1.9rem;
        font-size: 0.18rem;
        color: @primary-color;
        line-height: 0.25rem;
        .flex-type();

        &::after {
          position: absolute;
          top: -0.11rem;
          right: 0;
          z-index: 0;
          background-color: @primary-color;
          content: '';
          .set-size(0.01rem, 0.82rem);
        }

        &__img {
          margin-right: 0.2rem;
          .set-size(0.6rem);
        }
      }

      &__content {
        padding-left: 0.64rem;
        font-size: 0.14rem;
        color: @text-color;
        line-height: 0.2rem;
        .flex-type();

        &__item {
          .flex-type(center, column);

          &:last-child {
            margin-left: 1.38rem;
          }

          &__img {
            .set-size(0.66rem, 0.57rem);
          }

          &__tips {
            margin: 0.1rem 0 0.23rem 0;
            line-height: 0.24rem;
          }
        }
      }
    }
  }
}

.process {
  margin-top: 0.72rem;

  &__img {
    .set-size(8.05rem, 1.12rem);
  }
}

.require {
  margin-top: 0.72rem;
  .flex-type(center, column);

  &__list {
    width: 8.45rem;
    .flex-type(space-between);

    &__item {
      font-size: 0.14rem;
      line-height: 0.24rem;
      color: @text-color;

      &__number {
        font-size: 0.24rem;
        color: @primary-color;
      }
    }
  }

  &__more {
    width: fit-content;
    padding: 2px 20px;
    margin-top: 0.24rem;
    font-size: 0.14rem;
    border: 1px solid @primary-color;
    border-radius: 50px;
    color: @primary-color;
    line-height: 0.2rem;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      color: @white-color !important;
      background: linear-gradient(
        90deg,
        rgba(252, 189, 109, 1) 0%,
        rgba(147, 85, 52, 1) 100%
      ) !important;
    }
  }
}

.become {
  margin-top: 0.72rem;

  &__list {
    .flex-type();

    &__item {
      font-size: 0.14rem;
      text-align: center;
      color: rgba(167, 104, 47, 1);
      line-height: 0.2rem;

      &:not(:last-child) {
        margin-right: 2.15rem;
      }

      &__img {
        margin-bottom: 0.11rem;
        .set-size(1.16rem);
      }
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);