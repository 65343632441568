@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.industry {
  box-sizing: border-box;
  padding: 0.12rem 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    width: 12rem;
    background: @white-color;
    padding: 0 1.77rem 0.5rem;
    .flex-type(center, column);

    img {
      max-width: 8.45rem;
    }
  }
}

.title {
  margin-bottom: 0.4rem;
  font-size: 0.32rem;
  font-weight: 700;
  text-align: center;
  color: @text-color;
  line-height: 0.45rem;
}

.content {
  width: 8.45rem;
  font-size: 0.16rem;
  color: @text-color;
  line-height: 0.3rem;
}

.profile {
  width: 8.45rem;
  margin-top: 0.88rem;
  .flex-type(center, column);

  &__logo {
    .set-size(4.81rem, 0.84rem);
  }

  &__info {
    width: 100%;
    margin-top: 0.68rem;
    .flex-type(space-between);
    align-items: flex-start;
  }

  &__img {
    .set-size(4.67rem, 3.04rem);
  }

  &__content {
    width: 3.39rem;
    font-size: 0.16rem;
    color: @text-color;
    line-height: 0.3rem;
  }

  &__cooperator {
    margin-top: 0.68rem;
  }
}

.structure {
  width: 8.45rem;
  margin-top: 0.95rem;
  .flex-type(center, column);

  &__list {
    width: 100%;
    margin-top: 0.56rem;
    flex-wrap: wrap;
    .flex-type();
  }

  &__item {
    display: flex;
    min-height: 1.2rem;

    &:nth-child(1),
    &:nth-child(3),
    &:nth-child(5) {
      min-width: 4.12rem;
    }

    &:nth-child(2n) {
      margin-left: 1rem;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      margin-bottom: 0.66rem;
    }

    &__avatar {
      margin-right: 0.4rem;
      border-radius: 50%;
      .set-size(1.2rem);
    }

    &__info {
      .flex-type(flex-start, column);

      &__emphasis {
        display: flex;
        align-items: flex-end;
        width: 100%;
      }
    }

    &__rank {
      font-size: 0.14rem;
      color: @primary-color;
      line-height: 0.2rem;
    }

    &__name {
      margin-left: 0.1rem;
      font-size: 0.25rem;
      font-weight: 700;
      color: @primary-color;
      line-height: 0.3rem;
    }

    &__appellation {
      margin-top: 0.12rem;
      font-size: 0.14rem;
      color: @text-color;
      line-height: 0.24rem;
    }
  }

  &__button {
    margin-top: 0.72rem;
    background: linear-gradient(90deg, rgba(232, 169, 89, 1) 0%, rgba(127, 65, 32, 1) 100%);
    border-radius: 0.23rem;
    font-size: 0.24rem;
    color: @white-color;
    line-height: 0.33rem;
    .set-size(4rem, 0.45rem);
  }
}

.event {
  width: 8.45rem;
  margin-top: 0.72rem;
  .flex-type(flex-start, column);

  &__item {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 0.38rem;
    }

    &__img {
      margin-right: 0.36rem;
      .set-size(2.88rem, 1.8rem);
    }

    &__title {
      margin-bottom: 0.18rem;
      font-size: 0.18rem;
      font-weight: 700;
      color: @text-color;
      line-height: 0.25rem;
      .ellipsis-multiple(1);
    }

    &__content {
      width: 5.23rem;
      font-size: 0.14rem;
      color: @text-color;
      line-height: 0.28rem;
    }
  }

  &__more {
    margin-top: 0.5rem;
    font-size: 0.16rem;
    line-height: 0.22rem;
    color: @text-color-secondary;
    cursor: pointer;
    .flex-type(center,column);
  }
}

.modal {
  padding: 0.46rem 0.23rem;
}

.apply {
  width: 7.63rem;
  padding: 0.3rem 0;
  .flex-type(space-around);

  &__item {
    font-size: 0.14rem;
    color: @text-color;
    line-height: 0.2rem;
    .flex-type(center, column);
    .set-size(0.8rem, 1.2rem);
  }

  &__img {
    position: relative;
    margin-bottom: 0.15rem;
    .set-size(0.6rem);

    &__default {
      position: absolute;
      top: 0;
      left: 0;
      .set-size(100%);
    }

    &__active {
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.4s all;
      .set-size(100%);
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);