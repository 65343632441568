@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.summit {
  position: relative;
  padding-bottom: 1.73rem;
  z-index: 0;
  background: rgba(0, 0, 0, 1);

  &__cover {
    position: absolute;
    left: 0;
    top: -0.5rem;
    z-index: -2;
    object-fit: cover;
    object-position: center;
    .set-size(100%, 5.5rem);
  }

  &__decoration {
    position: absolute;
    left: 0;
    top: -0.5rem;
    z-index: -4;
    background: left -12% / 100% 12.4rem no-repeat url(https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/pc/summit-bg-2.png),
      left 11.49rem / 100% 12.4rem no-repeat
        url(https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/pc/summit-bg-2.png);
    .set-size(100%);
  }

  &__content {
    z-index: 1;
    padding-top: 5.5rem;
    .flex-type(center, column);
  }

  &__btn {
    z-index: 1;
    margin-top: 0.8rem;
    font-size: 0.18rem;
    color: @white-color;
    border-radius: 0.25rem;
    background: linear-gradient(90deg, rgb(232, 169, 89, 1) 0%, rgb(127, 65, 32, 1) 100%);
    .set-size(4rem, 0.45rem);
  }
}

.subtitle {
  z-index: 1;
  margin-bottom: 0.38rem;
  font-size: 0.32rem;
  font-weight: 700;
  text-align: center;
  color: rgba(224, 178, 132, 1);
  line-height: 0.45rem;
}

.brief {
  display: flex;
  z-index: 1;
  .set-size(12rem, 2.44rem);

  &__logo {
    margin: 0.8rem 1.76rem 0 0.9rem;
    .set-size(4.37rem, 0.61rem);
  }

  &__text {
    width: 4.37rem;
    margin-top: 0.64rem;
    font-size: 0.14rem;
    line-height: 0.3rem;
    color: @white-color;
  }
}

.scene {
  z-index: 1;
  .set-size(12rem, 6.74rem);
  .flex-type(space-between);

  &__left {
    display: flex;
    flex-wrap: wrap;
    width: 6.94rem;
    margin-right: 0.1rem;

    &__small {
      .set-size(3.42rem, 2.28rem);

      &:first-child {
        margin-right: 0.1rem;
      }
    }

    &__large {
      margin-top: 0.1rem;
      .set-size(6.94rem, 4.36rem);
    }
  }

  &__right {
    width: 4.98rem;

    &__item {
      .set-size(4.98rem, 3.32rem);

      &:not(:last-child) {
        margin-bottom: 0.1rem;
      }
    }
  }
}

.winner {
  z-index: 1;
  margin-top: 0.53rem;

  &__tab {
    width: 100%;
    background: transparent;
    color: @white-color;
    font-size: 0.16rem;

    &__item {
      width: 1.5rem;
      text-align: center;

      &:nth-child(1) {
        // margin-right: 2rem;
      }

      &:hover {
        color: @white-color;
      }
    }

    &__active {
      color: @white-color;
    }
  }

  &__list {
    width: 11.12rem;
    flex-wrap: wrap;
    .flex-type(center);

    &__item {
      margin: 0 0.88rem 0.08rem 0;
      .set-size(1.52rem, 3.55rem);

      &:nth-child(5n) {
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.process {
  position: relative;
  z-index: 1;
  margin-top: 0.72rem;

  & &__bg {
    position: absolute;
    top: -0.16rem;
    left: -3.78rem;
    z-index: 0;
    width: 14.33rem;
    height: 10.49rem;
  }

  &__img {
    max-width: 8.45rem;
  }

  &__text {
    width: 8.45rem;
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: @text-color-tertiary;
  }
}

.back {
  z-index: 1;
  margin-top: 0.72rem;

  &__item {
    display: flex;
    transition: all 0.25s;
    cursor: pointer;
    .set-size(12rem, 3rem);

    &:not(:last-child) {
      margin-bottom: 0.38rem;
    }

    &:hover {
      box-shadow: 0 0.04rem 0.46rem 0 @primary-color;
    }

    &__text {
      font-size: 1.2rem;
      font-weight: 200;
      font-family: PingFangSC-Ultralight, PingFang SC;
      color: @primary-color;
      line-height: 1rem;
      .set-size(2.4rem, 100%);
    }

    &__img {
      .set-size(9.6rem, 100%);
    }
  }
}

.cooperator {
  z-index: 1;
  margin-top: 0.72rem;

  &__img {
    .set-size(12rem, 2.45rem);

    & > img {
      max-width: 12rem;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);