@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.local {
  box-sizing: border-box;
  padding: 0.12rem 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    width: 12rem;
    background: @white-color;
    padding: 0 1.77rem 0.5rem;
    font-weight: 700;
    text-align: center;
    color: @text-color;
    .flex-type(center, column);

    img {
      max-width: 8.45rem;
    }
  }
}

.title {
  margin-bottom: 0.4rem;
  font-size: 0.32rem;
  line-height: 0.45rem;
}

.subtitle {
  margin-bottom: 0.4rem;
  font-size: 0.24rem;
  line-height: 0.33rem;
}

.tertiary-title {
  margin-bottom: 0.4rem;
  font-size: 0.18rem;
  line-height: 0.25rem;
}

.content {
  width: 8.45rem;
  font-size: 0.16rem;
  font-weight: 400;
  text-align: left;
  color: @text-color;
  line-height: 0.3rem;
}

.profile {
  width: 8.45rem;
  margin-top: 0.94rem;
}

.Patrol {
  .flex-type(center, column);

  &__img {
    margin: 0.24rem 0;
  }
}

.teacher {
  margin-top: 0.72rem;
  .flex-type(center, column);

  &__list {
    flex-wrap: wrap;
    margin-bottom: 0.24rem;
    .flex-type();

    &__item {
      font-size: 0.14rem;
      color: rgba(138, 138, 138, 1);
      line-height: 0.2rem;
      text-align: center;
      .set-size(2.6rem, 2.21rem);

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5) {
        margin-right: 0.32rem;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-bottom: 0.38rem;
      }

      &__img {
        margin-bottom: 0.15rem;
        .set-size(2.6rem, 1.86rem);
      }
    }
  }

  &__register {
    margin-top: 0.54rem;
    background: linear-gradient(90deg, rgba(232, 169, 89, 1) 0%, rgba(127, 65, 32, 1) 100%);
    border-radius: 0.23rem;
    font-size: 0.24rem;
    font-weight: 700;
    color: @white-color;
    line-height: 0.33rem;
    .set-size(4rem, 0.45rem);
  }
}

.class {
  margin-top: 0.72rem;

  &__content {
    margin: 0.24rem 0 0.72rem 0;
  }
}

.case {
  margin-top: 0.7rem;
  .flex-type(center, column);
}

@primary-color: rgba(204, 140, 75, 1);