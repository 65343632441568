@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.menu {
  width: 100%;
  background: @white-color;

  &__item {
    width: 100%;
    text-align: center;
    font-size: 0.4rem;
    color: @text-color;
    line-height: 1rem;

    &--active {
      color: @primary-color;
    }

    &__content {
      display: inline-block;
      .set-size(100%, 1rem);
      .flex-type(center);
    }
  }

  &__subItem {
    max-height: 100vh;
    background-color: rgba(249, 249, 249, 1);
    text-align: center;
    font-size: 0.32rem;
    color: rgba(138, 138, 138, 1);
    transition: 0.25s all ease;
    transform-origin: center 0;

    &--hidden {
      max-height: 0;
      overflow: hidden;
    }

    &__ins {
      width: 100%;
      min-height: 1rem;
      line-height: 1rem;

      &--active {
        color: @primary-color;
      }
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);