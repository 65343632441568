@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
@line-color: rgba(207, 156, 110, 1);

.line {
  background: @white-color;
  border-radius: 0.08rem;
  border: 1px solid rgba(230, 231, 232, 1);
  transition: all 0.25s;
  cursor: pointer;
  .set-size(100%);

  &:hover {
    box-shadow: 0 0.06rem 0.1rem 0 rgba(244, 223, 188, 1);
  }

  &--horizontal {
    box-sizing: border-box;
    padding: 0 0.28rem 0 0.24rem;
    .flex-type();

    .line__number {
      position: relative;
      flex-shrink: 0;
      width: 0.9rem;
      font-size: 0.18rem;
      color: @line-color;
      line-height: 0.28rem;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        width: 0.01rem;
        height: 0.31rem;
        background-color: @line-color;
        content: '';
      }
    }

    .line__title {
      margin-left: 0.34rem;
      font-size: 0.16rem;
      color: @text-color;
      line-height: 0.28rem;
      .ellipsis-multiple(1);
    }
  }

  &--vertical {
    padding: 0.06rem 0.14rem;
    font-size: 0.16rem;
    line-height: 0.3rem;

    &--active {
      background: @line-color;
      box-shadow: 0 0.06rem 0.1rem 0 rgba(244, 223, 188, 1);
    }

    .line__number {
      color: @line-color;

      &--active {
        color: @white-color;
      }
    }

    .line__title {
      color: @text-color;
      line-height: 0.26rem;
      .ellipsis-multiple(2);
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);