@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.tank {
  box-sizing: border-box;
  padding: 1.52rem 0.54rem;
  .set-size(100%);
  .flex-type(center, column);

  img {
    max-width: 6.42rem;
  }

  &__tab {
    box-sizing: border-box;
    width: 100vw;
    margin-bottom: 0.5rem;

    &__ins {
      z-index: 2000;
    }

    &__item {
      position: relative;
      min-width: fit-content;
      flex-shrink: 1;
      text-align: center;
      font-size: 0.24rem;
      color: rgba(138, 138, 138, 1);
      .set-size(2.28rem, 100%);

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        border-radius: 0.03rem;
        opacity: 0.43;
        backdrop-filter: blur(8px);
        background-color: rgba(217, 217, 217, 1);
        content: '';
        .set-size(100%, 0.06rem);
      }
    }

    &__active {
      font-size: 0.28rem;
      font-weight: 700;
      color: @primary-color;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        opacity: 1;
        border-radius: 0.03rem;
        backdrop-filter: blur(8px);
        background-color: @primary-color;
        content: '';
        .set-size(100%, 0.06rem);
      }
    }
  }
}

.content {
  width: 100%;
  font-size: 0.28rem;
  color: @text-color;
  line-height: 0.56rem;
}

.profile {
  width: 100%;
  .flex-type(center, column);

  &__logo {
    margin-bottom: 0.88rem;
    .set-size(6.42rem, 0.58rem);
  }

  &__img {
    margin-bottom: 0.16rem;

    &:nth-of-type(2) {
      margin-top: 0.48rem;
    }
  }
}

.initiator {
  width: 100%;

  &__list {
    font-size: 0.28rem;
    color: @text-color;
    line-height: 0.56rem;

    &__item {
      &:nth-child(5n) {
        margin-bottom: 0.32rem;
      }
    }
  }
}

.structure {
  box-sizing: border-box;
  width: 100%;

  &__item {
    display: flex;
    width: 100%;
    min-height: 1.8rem;

    &:not(:last-child) {
      margin-bottom: 0.64rem;
    }

    &__avatar {
      margin-right: 0.48rem;
      border-radius: 50%;
      .set-size(1.8rem);
    }

    &__rank {
      margin-top: 0.1rem;
      font-size: 0.24rem;
      color: @primary-color;
      line-height: 0.34rem;
    }

    &__name {
      margin-top: 0.1rem;
      font-size: 0.36rem;
      font-weight: 700;
      color: @primary-color;
      line-height: 0.34rem;
    }

    &__appellation {
      margin-top: 0.16rem;
      font-size: 0.24rem;
      color: @text-color;
      line-height: 0.44rem;
    }
  }
}

.activity {
  width: 100%;
  .flex-type(center, column);

  &__content {
    &:nth-of-type(n + 2) {
      position: relative;
      padding-bottom: 0.48rem;

      &::after {
        position: absolute;
        bottom: 0;
        left: -0.22rem;
        background-color: rgba(230, 231, 232, 1);
        content: '';
        .set-size(6.86rem, 0.02rem);
      }
    }
  }

  &__img__ins {
    &:first-of-type {
      margin-top: 0.48rem;
    }

    &:not(:last-of-type) {
      margin-bottom: 0.14rem;
    }

    &:last-of-type {
      margin-bottom: 0.48rem;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);