@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.card {
  box-sizing: border-box;
  width: 6.86rem;
  padding-bottom: 0.32rem;
  border-bottom: 1px solid rgba(230, 231, 232, 1);
  background: @white-color;
  font-size: 0.32rem;
  line-height: 0.48rem;
  color: @text-color;

  &--vertical {
    .card__img {
      .set-size(6.86rem, 4.29rem);
    }

    .card__info {
      margin-top: 0.24rem;
    }
  }

  &--horizontal {
    .flex-type();

    .card__img {
      flex-shrink: 0;
      margin-left: 0.5rem;
      .set-size(2.3rem, 1.44rem);
    }
  }

  &__info {
    &__title {
      font-weight: 700;
      .ellipsis-multiple(2);

      &--summit {
        color: @primary-color;
      }
    }

    &__time {
      font-size: 0.24rem;
      line-height: 0.56rem;
      color: @text-color-tertiary;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);