@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.committee {
  box-sizing: border-box;
  width: 6.86rem;
  padding: 0.4rem 0.4rem 0.16rem;
  background-color: @white-color;
  border-radius: 0.4rem;
  border: 1px solid rgba(229, 229, 229, 1);
  .flex-type(center, column);

  &__department {
    display: flex;
    align-items: flex-end;
    width: 100%;
    color: @primary-color;
    line-height: 0.4rem;

    &__icon {
      margin-right: 0.38rem;
      .set-size(0.68rem, 0.8rem);
    }

    &__info {
      & > div:nth-of-type(1) {
        font-size: 0.28rem;
        font-weight: 700;
      }

      & > div:nth-of-type(2) {
        font-size: 0.2rem;
      }
    }
  }

  &__director {
    width: 100%;
    margin-top: 0.48rem;
    .flex-type();

    &__avatar {
      margin-right: 0.3rem;
      .set-size(1.8rem);
    }

    &__name {
      font-size: 0.36rem;
      font-weight: 700;
      color: @primary-color;
      line-height: 0.4rem;

      & > span:nth-of-type(1) {
        font-size: 0.28rem;
        margin-right: 0.4rem;
      }
    }

    &__appellation {
      font-size: 0.24rem;
      color: @primary-color;
      line-height: 0.4rem;
    }
  }

  &__icon {
    margin-top: 0.28rem;
    .flex-type(center);
    .set-size(100% , 0.4rem);

    &__img {
      transition: 0.25s all;
      .set-size(0.16rem, 0.26rem);

      &--up {
        transform: rotate(-90deg);
      }

      &--down {
        transform: rotate(90deg);
      }
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);