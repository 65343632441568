@import '../../assets/styles/abstracts/mixins.less';
@import '../../assets/styles/abstracts/function.less';
@import '../../assets/styles/abstracts/variables.less';
@import '../../assets/styles/abstracts/reset.less';
@import '../../assets/styles/abstracts/global.less';
.back {
  position: absolute;
  bottom: 0.5rem;
  right: 0.2rem;
  font-size: 0.12rem;
  line-height: 0.17rem;
  color: rgba(219, 219, 219, 1);
  cursor: pointer;
  .set-size(0.6rem);
  .flex-type(center, column);

  &__icon {
    margin-bottom: 0.09rem;
    border-radius: 50%;
    .set-size(0.4rem);
  }
}

@primary-color: rgba(204, 140, 75, 1);