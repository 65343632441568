@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
.more {
  box-sizing: border-box;
  padding: 0.12rem 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    width: 12rem;
    background: @white-color;
    padding: 0 1.77rem 0.5rem;
    .flex-type(center, column);

    img {
      max-width: 8.45rem;
    }
  }
}

.condition {
  padding-top: 0.13rem;
  .flex-type(center, column);

  &__item {
    margin-top: 0.72rem;
    .flex-type(center, column);

    &__label {
      font-size: 0.32rem;
      font-weight: 700;
      color: @text-color;
      line-height: 0.45rem;
    }

    &__list {
      width: 8.1rem;
      margin-top: 0.35rem;
      font-size: 0.14rem;
      color: @text-color;
      line-height: 0.32rem;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);