@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
.more {
  box-sizing: border-box;
  padding: 0.48rem 0.54rem 1rem;
  .set-size(100%);
  .flex-type(center, column);
}

.condition {
  width: 100%;
  .flex-type(center, column);

  &__item {
    width: 100%;
    .flex-type(center, column);

    &:nth-child(n + 2) {
      margin-top: 0.8rem;
    }

    &__label {
      width: 100%;
      font-size: 0.36rem;
      font-weight: 700;
      color: @text-color;
      line-height: 0.56rem;
    }

    &__list {
      width: 100%;
      margin-top: 0.48rem;
      font-size: 0.28rem;
      color: @text-color;
      line-height: 0.5rem;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);