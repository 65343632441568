@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.committee {
  box-sizing: border-box;
  width: 9.36rem;
  padding: 0.28rem 0.6rem;
  background-color: @white-color;
  border-radius: 0.2rem;
  border: 1px solid @primary-color;
  .set-size(9.36rem, 3.02rem);

  &__department {
    display: flex;
    align-items: flex-end;
    width: 100%;
    color: @primary-color;
    line-height: 0.25rem;

    &__icon {
      margin-right: 0.2rem;
      .set-size(0.46rem);
    }

    & > span:nth-of-type(1) {
      margin-right: 0.1rem;
      font-size: 0.18rem;
      font-weight: 700;
    }

    & > span:nth-of-type(2) {
      font-size: 0.12rem;
      line-height: 0.22rem;
    }
  }

  &__director {
    display: flex;
    width: 7.6rem;
    margin: 0.32rem 0 0 0.53rem;

    &__avatar {
      margin: 0.06rem 0.24rem 0 0;
      .set-size(1rem);
    }

    &__name {
      font-size: 0.25rem;
      font-weight: 700;
      color: @primary-color;
      line-height: 0.36rem;

      & > span:nth-of-type(1) {
        margin-right: 0.14rem;
      }
    }

    &__appellation {
      font-size: 0.14rem;
      color: @primary-color;
      line-height: 0.36rem;
    }

    &__profile {
      width: 6.36rem;
      margin-top: 0.2rem;
      font-size: 0.14rem;
      color: @text-color;
      line-height: 0.29rem;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);