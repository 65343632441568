@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.member {
  width: 100%;

  &__tab {
    z-index: 2999;
    background: rgba(249, 249, 249, 1);

    &__item {
      flex-shrink: 1;
      width: 100%;
      text-align: center;
      font-size: 0.24rem;
      color: rgba(138, 138, 138, 1);
    }

    &__active {
      font-size: 0.28rem;
      font-weight: 700;
      color: @primary-color;
    }
  }
}

.profile {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 0.64rem 0.54rem;
  color: @white-color;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    .set-size(100%);
  }

  &__title {
    font-size: 0.24rem;
    line-height: 0.34rem;

    &__text {
      font-size: 0.48rem;
      font-weight: 700;
      line-height: 0.66rem;
    }
  }

  &__text {
    width: 100%;
    margin-top: 0.62rem;
    font-size: 0.24rem;
    line-height: 0.48rem;
  }
}

@primary-color: rgba(204, 140, 75, 1);