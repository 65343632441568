@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.branch {
  box-sizing: border-box;
  padding-bottom: 1rem;
  .set-size(100%);
  .flex-type(center, column);

  &__tab {
    width: 100%;

    &__ins {
      box-sizing: border-box;
      width: 100%;
      padding: 0 0.32rem;
      background-color: @white-color;
      z-index: 3999;
    }

    &__item {
      position: relative;
      flex-shrink: 1;
      min-width: fit-content;
      text-align: center;
      font-size: 0.24rem;
      color: rgba(138, 138, 138, 1);
      .set-size(100%);

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        background-color: rgba(217, 217, 217, 1);
        border-radius: 0.03rem;
        opacity: 0.43;
        backdrop-filter: blur(8px);
        content: '';
        .set-size(100%, 0.06rem);
      }
    }

    &__active {
      position: relative;
      font-size: 0.28rem;
      font-weight: 700;
      color: @primary-color;
      .set-size(100%);

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        background-color: @primary-color;
        border-radius: 0.03rem;
        backdrop-filter: blur(8px);
        opacity: 1;
        content: '';
        .set-size(100%, 0.06rem);
      }
    }
  }

  &__list {
    box-sizing: border-box;
    width: 100%;
    padding: 0 0.32rem;
    margin-top: 0.32rem;

    &__item {
      margin-bottom: 0.32rem;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);