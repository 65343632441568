@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.rules {
  box-sizing: border-box;
  padding: 0.48rem 0.32rem 1rem;
  .set-size(100%);
  .flex-type(center, column);
}

.logo {
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid @primary-color;
  .flex-type(center, column);

  &__img {
    .set-size(2.1rem);
  }
}

.content {
  margin-top: 0.8rem;
}

.institution {
  width: 100%;
  margin: 1rem 0 0.6rem 0;

  &__title {
    margin-bottom: 0.6rem;
    font-size: 0.36rem;
    font-weight: 700;
    text-align: center;
    color: @text-color;
    line-height: 0.56rem;
  }

  &__ins {
    font-size: 0.28rem;
    color: @text-color;
    line-height: 0.82rem;
    cursor: pointer;
  }
}

@primary-color: rgba(204, 140, 75, 1);