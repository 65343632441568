@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.home {
  padding-bottom: 0.8rem;
  background-color: @white-color;
}

.banner {
  box-sizing: border-box;
  // padding: 0 1.6rem;
  background: rgb(255, 255, 255);
  min-width: 12rem;
  .set-size(100%, 4.8rem);
  .flex-type(center);

  &__swiper {
    min-width: 12rem;
    align-items: flex-start;
    .set-size(100%, 4.8rem);
  }

  &__img {
    width: 100%;
    height: 4.8rem !important;
    object-fit: cover;
    cursor: pointer;
  }
}

.title {
  margin-bottom: 0.32rem;
  font-size: 0.36rem;
  font-weight: 700;
  color: @text-color;
  line-height: 0.5rem;
}

.more {
  width: 12rem;
  margin-bottom: 0.12rem;
  font-size: 0.14rem;
  color: @text-color-tertiary;
  line-height: 0.28rem;
  .flex-type(flex-end);

  &__instance {
    cursor: pointer;
    .flex-type();

    &:hover,
    &:active {
      color: @primary-color !important;

      .more__arrow {
        color: @primary-color !important;
      }
    }
  }

  &__arrow {
    color: rgba(167, 167, 167, 1) !important;
  }
}

.project {
  margin-top: 0.9rem;
  .flex-type(center, column);

  &__container {
    .set-size(12rem, 3.34rem);
    .flex-type();
  }

  &__swiper__ins {
    .set-size(100%);
  }

  &__info {
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    padding: 0.55rem 0.72rem 0 0.71rem;
    .set-size(50%, 100%);

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      .set-size(100%);
    }

    &__title {
      font-size: 0.25rem;
      font-weight: 700;
      color: @white-color;
      line-height: 0.42rem;
    }

    &__content {
      margin-top: 0.14rem;
      font-size: 0.14rem;
      color: @white-color;
      line-height: 0.26rem;
    }

    :global(.ant-btn) {
      border: 1px solid @primary-color !important;
    }

    &__detail {
      position: absolute;
      left: 0.71rem;
      bottom: 0.4rem;
      border-radius: 0.5rem;
      background: transparent;
      color: @primary-color;
      .set-size(1.24rem, 0.25rem);

      &:hover {
        border: 0 !important;
      }
    }
  }

  &__img {
    .set-size(50%, 100%);

    &--instance {
      .set-size(100%, 100%);
    }
  }
}

.interview {
  margin-top: 0.77rem;
  background: rgba(244, 245, 246, 1);
  .set-size(100%, 5.13rem);
  .flex-type(center, column);

  &__container {
    .set-size(12rem, 3.22rem);
    .flex-type(space-between);
  }

  &__card-box {
    .flex-type();

    &__item:not(:last-child) {
      margin-right: 0.32rem;
    }
  }

  &__line-box {
    width: 6.24rem;

    &__item {
      .set-size(6.24rem, 0.5rem);
    }

    &__item:not(:last-child) {
      margin-bottom: 0.18rem;
    }
  }
}

.news {
  margin-top: 0.76rem;
  .flex-type(center, column);

  &__box {
    width: 12rem;
    .flex-type(space-between);
  }
}

:global(.ant-image) {
  width: 100%;
  height: 100%;
}

:global(.ant-image-img) {
  width: 100%;
  height: 100%;
}

@primary-color: rgba(204, 140, 75, 1);