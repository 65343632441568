@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.local {
  box-sizing: border-box;
  padding: 0.8rem 0.54rem 2rem;
  .set-size(100%);
  .flex-type(center, column);

  img {
    max-width: 6.42rem;
  }
}

.title {
  width: 100%;
  font-size: 0.36rem;
  font-weight: 700;
  line-height: 0.5rem;
}

.content {
  width: 100%;
  margin-top: 0.48rem;
  font-size: 0.28rem;
  color: @text-color;
  line-height: 0.56rem;
}

.Patrol {
  width: 100%;
  margin-top: 0.48rem;
  .flex-type(center, column);

  &__img {
    &:nth-of-type(1) {
      margin-top: 0.48rem;
    }

    &:not(:last-of-type) {
      margin-bottom: 0.16rem;
    }
  }
}

.teacher {
  width: 100%;
  margin-top: 0.8rem;

  &__list {
    width: 100vw;
    margin-top: 0.48rem;

    &__item {
      border-radius: 0.16rem;
      text-align: center;
      background-color: rgba(244, 245, 246, 1);
      font-size: 0.24rem;
      color: rgba(138, 138, 138, 1);
      line-height: 0.34rem;
      .set-size(5.14rem, 4.66rem);

      &__img {
        margin-bottom: 0.32rem;
        border-radius: 0.16rem 0.16rem 0 0;
        .set-size(5.14rem, 3.66rem);
      }
    }
  }

  &__register {
    z-index: 2001;
    .set-size(7.5rem, 1.2rem);
    .flex-type(center);

    &--fixed {
      position: fixed;
      bottom: 0;
      left: 0;
    }

    &--absolute {
      position: absolute;
      bottom: 0.4rem;
      left: 0;
    }

    &__ins {
      background: linear-gradient(90deg, rgba(232, 169, 89, 1) 0%, rgba(127, 65, 32, 1) 100%);
      border-radius: 0.5rem;
      font-size: 0.4rem;
      font-weight: 700;
      color: @white-color;
      .set-size(6.86rem, 0.88rem);
    }
  }
}

.class {
  margin-top: 0.48rem;

  &__item {
    margin-bottom: 0.48rem;

    &__img {
      &:nth-of-type(1) {
        margin-top: 0.48rem;
      }

      &:not(:last-of-type) {
        margin-bottom: 0.16rem;
      }
    }
  }
}

.case {
  width: 100%;
  margin-top: 0.8rem;
  .flex-type(center, column);

  &__tab {
    box-sizing: border-box;
    width: 100vw;
    margin-top: 0.3rem;

    &__ins {
      z-index: 2000;
    }

    &__item {
      position: relative;
      min-width: fit-content;
      flex-shrink: 1;
      text-align: center;
      font-size: 0.24rem;
      color: rgba(138, 138, 138, 1);
      .set-size(3.43rem, 100%);

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        border-radius: 0.03rem;
        opacity: 0.43;
        backdrop-filter: blur(8px);
        background-color: rgba(217, 217, 217, 1);
        content: '';
        .set-size(100%, 0.06rem);
      }
    }

    &__active {
      font-size: 0.28rem;
      font-weight: 700;
      color: @primary-color;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        opacity: 1;
        border-radius: 0.03rem;
        backdrop-filter: blur(8px);
        background-color: @primary-color;
        content: '';
        .set-size(100%, 0.06rem);
      }
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);