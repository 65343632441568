@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.about {
  width: 100%;

  &__tab {
    z-index: 2999;
    background: rgba(240, 242, 245, 1);

    &__item {
      flex-shrink: 1;
      width: 100%;
      min-width: fit-content;
      text-align: center;
      font-size: 0.24rem;
      color: rgba(138, 138, 138, 1);
    }

    &__active {
      font-size: 0.28rem;
      font-weight: 700;
      color: @primary-color;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);