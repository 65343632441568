@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.rules {
  box-sizing: border-box;
  padding: 0 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    width: 12rem;
    background: @white-color;
    padding: 0.16rem 1.77rem 0.86rem;
    .flex-type(center, column);
  }
}

.logo {
  width: 10rem;
  margin-top: 1rem;
  padding-bottom: 0.63rem;
  border-bottom: 1px solid @primary-color;
  .flex-type(center, column);

  &__img {
    .set-size(1.58rem);
  }
}

.content {
  margin-top: 0.5rem;
}

.institution {
  width: 100%;
  margin: 1rem 0 0.67rem 0;

  &__title {
    margin-bottom: 0.67rem;
    font-size: 0.32rem;
    font-weight: 700;
    text-align: center;
    color: @text-color;
    line-height: 0.45rem;
  }

  &__ins {
    width: fit-content;
    font-size: 0.16rem;
    color: @text-color;
    line-height: 0.48rem;
    cursor: pointer;

    &:hover {
      color: @primary-color;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);