@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.committee-profile {
  visibility: hidden;
  width: 5.88rem;
  max-height: 0;
  margin: 0;
  font-size: 0.28rem;
  color: @text-color;
  line-height: 0.56rem;
  transition: all 0.3s;
  transform-origin: 50% 0;

  &-enter {
    visibility: visible;
    max-height: 0;
  }

  &-enter-active {
    margin-top: 0.38rem;
    max-height: 2.8rem;
  }

  &-enter-done {
    visibility: visible;
    margin-top: 0.38rem;
    max-height: 2.8rem;
  }

  &-exit {
    visibility: visible;
    max-height: 2.8rem;
    margin-top: 0.38rem;
    opacity: 1;
  }

  &-exit-active {
    max-height: 0;
    margin: 0;
    opacity: 0;
  }

  &-exit-done {
    visibility: hidden;
    max-height: 0;
    margin: 0;
    opacity: 0;
  }
}

@primary-color: rgba(204, 140, 75, 1);