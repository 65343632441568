@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.merchant {
  .flex-type(center, column);

  img {
    max-width: 6.2rem;
  }

  &__logo {
    margin-bottom: 0.66rem !important;
    .set-size(2.53rem, 0.78rem);
  }

  &__content {
    font-size: 0.16rem;
    color: @text-color;
    line-height: 0.3rem;
  }

  &__scene {
    margin: 0.38rem 0 !important;
    .set-size(6.2rem, 2.62rem);
  }

  &__split {
    margin: 0.38rem 0 0.14rem 0 !important;
    .set-size(0.18rem);
  }

  &__book {
    .set-size(6.29rem, 3.1rem);
  }

  &__button {
    background: linear-gradient(90deg, rgba(232, 169, 89, 1) 0%, rgba(127, 65, 32, 1) 100%);
    border-radius: 0.23rem;
    font-size: 0.24rem;
    font-weight: 700;
    color: @white-color;
    line-height: 0.33rem;
    .set-size(4rem, 0.45rem);
  }
}

@primary-color: rgba(204, 140, 75, 1);