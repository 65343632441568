@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
@title-color: rgba(224, 178, 132, 1);

.summit {
  padding-bottom: 0.8rem;
  background: rgba(3, 6, 36, 1);
  .flex-type(center, column);
}

.subtitle {
  font-size: 0.32rem;
  font-weight: 700;
  color: @title-color;
  line-height: 0.45rem;
}

.pv {
  position: relative;
  .set-size(100%, 6.36rem);

  &__img {
    object-fit: cover;
    object-position: center;
    .set-size(100%);
  }

  &__icon {
    position: absolute;
    margin: auto;
    bottom: 0.68rem;
    left: 0;
    right: 0;
    z-index: 1;
    cursor: pointer;
    .set-size(0.6rem);
  }

  &__detail {
    position: absolute;
    bottom: 0.22rem;
    left: 0;
    z-index: 10;
    text-align: center;
    .set-size(100%,0.3rem);
    font-size: 0.14rem;
    color: rgba(255, 255, 255, 1);
    line-height: 0.3rem;
  }
}

.tag {
  width: 12rem;
  margin-top: 0.57rem;
  .flex-type(space-between);

  &__card {
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 0.32rem;
    border-radius: 0.12rem;
    font-size: 0.24rem;
    color: @primary-color;
    line-height: 0.6rem;
    z-index: 0;
    .set-size(3.96rem, 2.1rem);

    &__card:not(:last-child) {
      margin-right: 0.05rem;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background: linear-gradient(180deg, @primary-color 0%, rgba(232, 192, 130, 0) 100%);
      opacity: 0.38;
      content: '';
      .set-size(100%);
    }
  }

  &__label {
    font-size: 0.58rem;
    font-weight: 700;
    line-height: 0.58rem;
  }
}

.cup {
  position: relative;
  z-index: 0;
  .set-size(100%, 3.55rem);
  .flex-type(center);

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    background-color: rgba(116, 90, 66, 1);
    content: '';
    .set-size(100%, 2.82rem);
  }

  &::after {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -2;
    background-color: rgba(0, 0, 0, 1);
    content: '';
    .set-size(19.2rem, 2.82rem);
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    .set-size(19.2rem, 100%);
  }

  &__container {
    position: relative;
    .set-size(12rem, 100%);
  }

  &__info {
    position: absolute;
    top: 0.56rem;
    right: 1.29rem;
    z-index: 1;

    &__text {
      width: 6.4rem;
      margin-top: 0.35rem;
      font-size: 0.16rem;
      color: rgba(227, 201, 177, 1);
      line-height: 0.3rem;
    }
  }
}

.theme {
  position: relative;
  width: 100%;
  z-index: 1;
  .flex-type(center, column);

  &__up,
  &__down {
    max-width: 19.2rem;
    max-height: 1.27rem;
    min-width: 15rem;
    width: 100%;
  }

  &__cover {
    position: relative;
  }

  &__ins {
    max-width: 12rem;
    margin: 0.67rem 0 0.38rem 0;
  }

  &__border__lb {
    position: absolute;
    bottom: 0;
    left: -0.62rem;
    z-index: 0;
    .set-size(4.88rem, 1.77rem);
  }

  &__border__rt {
    position: absolute;
    top: 0;
    right: -0.62rem;
    z-index: 0;
    .set-size(4.88rem, 1.77rem);
  }

  &__content {
    position: absolute;
    bottom: 0.14rem;
    z-index: 1;
    width: 9.5rem;
    font-size: 0.14rem;
    color: @white-color;
    line-height: 0.3rem;
  }
}

.moment {
  width: 9.5rem;
  margin-top: 0.72rem;
  .flex-type(center, column);

  &__info {
    margin-top: 0.4rem;
    font-size: 0.16rem;
    color: rgba(217, 217, 217, 1);
    line-height: 0.3rem;
  }
}

.winner {
  width: 100%;
  margin-top: 0.38rem;
  font-size: 0.16rem;
  color: rgba(217, 217, 217, 1);
  line-height: 0.3rem;

  &__item {
    padding: 0.32rem 0;
    .flex-type(center);

    &--even {
      background: rgba(255, 198, 145, 0.2);
    }

    &__container {
      width: 9.5rem;
      .flex-type(space-between);
    }

    &__left {
      // margin-right: 1.03rem;
    }

    &__img {
      .flex-type();
    }

    &__ins {
      margin-right: 0.28rem;
      .set-size(1.52rem, 3.55rem);

      &:last-child {
        margin-right: 0;
      }
    }

    &__words {
      min-width: 3.32rem;

      &__para {
        margin-bottom: 0.3rem;
      }

      &__para:nth-child(2) {
        margin-top: 0.25rem;
      }

      &__para:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.judges {
  width: 100%;
  position: relative;
  padding: 0.38rem 0 0.53rem 0;
  margin-top: 0.72rem;
  z-index: 0;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(180deg, rgba(236, 158, 46, 1) 0%, rgba(111, 71, 10, 1) 100%);
    opacity: 0.38;
    content: '';
    .set-size(100%);
  }

  &__container {
    z-index: 1;
    .flex-type(center, column);
  }

  &__subtitle {
    margin: 0.38rem 0;
    font-size: 0.18rem;
    font-weight: 700;
    color: @title-color;
    line-height: 0.25rem;
  }

  &__list {
    .flex-type(center);

    &__item {
      margin-right: 0.4rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.specimens {
  margin-top: 0.72rem;
  .flex-type(center, column);

  &__swiper {
    width: 12rem;
  }

  &__swiper__ins {
    padding: 0.3rem 0.1rem 0.58rem;
  }

  & &__img {
    transition: all 0.25s;
    cursor: pointer;
    .set-size(100%, 2.53rem);

    &:hover {
      box-shadow: 0 0.04rem 0.46rem 0 rgba(204, 140, 75, 0.86);
    }
  }
}

.news {
  .flex-type(center, column);

  &__page {
    flex-wrap: wrap;
    .flex-type();
  }

  &__item {
    color: rgba(207, 156, 110, 1);
    background: transparent;

    &:hover {
      box-shadow: 0 0.04rem 0.46rem 0 @primary-color;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(5) {
      margin-right: 0.36rem;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-bottom: 0.46rem;
    }
  }

  &__swiper {
    width: 12.4rem;
  }

  &__swiper__ins {
    box-sizing: border-box;
    padding: 0.4rem;
    width: 12.4rem;
    // .set-size(12.4rem, 8rem);
  }
}

.structure {
  width: 100%;
  margin-top: 0.2rem;
  .flex-type(center, column);

  &__box {
    position: relative;
    width: 100%;
    z-index: 0;
    padding: 0.31rem 0;
    margin-top: 0.38rem;
    .flex-type(center);

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background: rgba(217, 217, 217, 1);
      opacity: 0.18;
      content: '';
      .set-size(100%);
    }
  }

  &__content {
    width: 12rem;
    .flex-type(space-between);
  }

  &__item {
    font-size: 0.14rem;
    color: @primary-color;
    line-height: 0.24rem;

    &__list {
      .flex-type();
    }

    &__organ {
      color: rgba(217, 217, 217, 1);

      &:not(:last-child) {
        margin-right: 0.14rem;
      }
    }

    &__split {
      background: rgba(151, 151, 151, 1);
      .set-size(0.01rem, 0.3rem);
    }
  }
}

.cooperator {
  z-index: 1;
  margin-top: 0.72rem;
  .flex-type(center, column);

  &__img {
    margin-top: 0.38rem;
    .set-size(12rem, 2.45rem);

    & > img {
      max-width: 12rem;
    }
  }
}

.video {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
  .set-size(100%);
  .flex-type(center);

  &__container {
    position: relative;
    width: 60%;
    margin-top: -0.8rem;
  }

  &__ins {
    width: 100%;
  }

  &__cancel {
    position: absolute;
    top: -0.32rem;
    right: -0.32rem;
    font-size: 0.3rem;
    color: rgba(151, 151, 151, 1);
    cursor: pointer;

    &:hover {
      color: rgba(167, 167, 167, 1);
    }
  }
}

.year-2023 {
  .structure__box:after {
    background: transparent;
  }
  .structure__content {
    width: 15rem;
  }

  .index_winner__item--even {
    background: rgba(255, 198, 145, 0.2);
  }
}

@primary-color: rgba(204, 140, 75, 1);