@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
.digit {
  width: 100%;

  &__tab {
    position: sticky;
    left: 0;
    top: 0.86rem;
    z-index: 10;

    &--transparent {
      color: @white-color;
      background: transparent;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(0.08rem);
        content: '';
        .set-size(100%);
      }
    }
  }
}

.banner {
  position: relative;
  z-index: 0;
  .set-size(100%, 2.9rem);
  .flex-type(center);

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    position: center;
    .set-size(100%);
  }

  &__container {
    position: relative;
    .set-size(12rem, 100%);
    .flex-type(space-between);
  }

  &__logo {
    .set-size(4.95rem, 1.04rem);
  }

  &__content {
    width: 6rem;
    font-size: 0.14rem;
    line-height: 0.3rem;
    color: @text-color;
  }
}

@primary-color: rgba(204, 140, 75, 1);