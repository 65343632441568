@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
.news {
  padding: 0.12rem 0 0.8rem 0;
  .set-size(100%);
  .flex-type(center, column);
}

.detail {
  box-sizing: border-box;
  width: 12rem;
  padding: 0.98rem 1.78rem 1.18rem;
  background: @white-color;
  .flex-type(center, column);

  &__title {
    font-size: 0.36rem;
    font-weight: 700;
    text-align: center;
    color: @text-color;
    line-height: 0.49rem;
    .ellipsis-multiple(2);
  }

  &__time {
    margin-top: 0.18rem;
    font-size: 0.14rem;
    font-weight: 700;
    color: @text-color-tertiary;
    line-height: 0.2rem;
  }

  &__article {
    margin-top: 0.72rem;
    font-size: 0.16rem;
    color: @text-color;
    line-height: 0.32rem;

    img {
      max-width: 8.45rem !important;
      height: auto !important;
      margin: 0.24rem 0;
    }
  }
}

.recommend {
  margin-top: 0.72rem;
  .flex-type(center, column);

  &__title {
    margin-bottom: 0.38rem;
    font-size: 0.32rem;
    font-weight: 700;
    color: @text-color;
    line-height: 0.45rem;
  }

  &__list {
    width: 12rem;
    .flex-type();

    &__item {
      margin-right: 0.63rem;
    }
  }

  &__more {
    margin-top: 0.6rem;
    border-radius: 0.23rem;
    border: 1px solid @text-color-tertiary;
    font-size: 0.18rem;
    color: @text-color-tertiary;
    line-height: 0.25rem;
    cursor: pointer;
    .set-size(1.54rem, 0.45rem);
    .flex-type(center);

    &:hover {
      color: @primary-color;
      border-color: @primary-color;
    }
  }
}

.appendix {
  width: 100%;
  margin-top: 0.8rem;
  font-size: 0.16rem;
  line-height: 0.32rem;

  &__title {
    font-weight: 700;
    color: @text-color;
    margin-bottom: 0.1rem;
  }

  &__item {
    width: fit-content;
    cursor: pointer;
    color: @primary-color;
    user-select: none;
    .ellipsis-multiple(1);
  }
}

@primary-color: rgba(204, 140, 75, 1);