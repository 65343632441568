@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.contact {
  box-sizing: border-box;
  padding: 0 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    display: flex;
    width: 12rem;
    background: @white-color;
    padding: 0.48rem 0.6rem 0.48rem 1rem;
  }
}

.map {
  &__ins {
    border-radius: 0.1rem;
    .set-size(4.62rem, 2.53rem);

    &:last-of-type {
      margin-top: 0.5rem;
    }
  }
}

.info {
  margin: 0.23rem 0 0 1.4rem;
  font-size: 0.14rem;
  color: @text-color;

  &__contact {
    &__line {
      line-height: 0.42rem;

      & > span:nth-of-type(1) {
        display: inline-block;
        width: 0.68rem;
        text-align: left;
        color: @primary-color;
      }
    }
  }

  &__address {
    margin: 0.24rem 0 0 0.32rem;

    &__line {
      display: flex;
      line-height: 0.28rem;

      &:last-of-type {
        // margin-top: 0.51rem;
      }
    }

    &__img {
      .set-size(0.19rem, 0.25rem);
    }

    &__detail {
      margin: 0.04rem 0 0 0.19rem;

      & > div:nth-of-type(1) {
        font-size: 0.18rem;
      }
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);