@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.trip {
  box-sizing: border-box;
  background: @white-color;
  .set-size(100%);
  .flex-type(center,column);

  &__content {
    box-sizing: border-box;
    width: 100%;
    padding: 0.48rem 0.54rem 1.28rem;
    border-bottom: 0.16rem solid rgba(249, 249, 249, 1);
    .flex-type(center,column);
  }

  &__tab {
    position: relative;
    width: 100%;

    &__ins {
      box-sizing: border-box;
      position: absolute;
      left: 0;
      top: 0;
      padding: 0 0.32rem;
      color: rgba(138, 138, 138, 1);
      font-size: 0.24rem;
      z-index: 3999;
      background-color: @white-color;
      .set-size(100%, 1rem);
      .flex-type();
    }

    &__item {
      position: relative;
      flex-shrink: 1;
      min-width: fit-content;
      text-align: center;
      font-weight: 700;
      line-height: 1rem;
      .set-size(100%);

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(217, 217, 217, 1);
        border-radius: 0.03rem;
        backdrop-filter: blur(0.08rem);
        content: '';
        .set-size(100%, 0.06rem);
      }
    }

    &__active {
      position: relative;
      font-size: 0.28rem;
      color: @primary-color;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        background-color: @primary-color;
        border-radius: 0.03rem;
        backdrop-filter: blur(0.08rem);
        content: '';
        .set-size(100%, 0.06rem);
      }
    }
  }
}

.profile {
  position: relative;
  z-index: 1;
  .set-size(100%, 9.9rem);

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    .set-size(7.5rem, 9.9rem);
  }

  &__logo {
    margin: 6.1rem 0 0 1.56rem;
    .set-size(4.39rem, 0.68rem);
  }

  &__text {
    width: 6.1rem;
    margin: 0.7rem 0 0 0.7rem;
    font-size: 0.28rem;
    line-height: 0.56rem;
    color: rgba(167, 167, 167, 1);
  }
}

.other {
  width: 100%;
  .flex-type(center,column);

  &__img {
    width: 100%;

    &__line1 {
      margin-bottom: 0.1rem;
      .flex-type();

      &__item {
        .set-size(3.16rem, 2.12rem);

        &:not(:last-child) {
          margin-right: 0.1rem;
        }
      }
    }

    &__line2 {
      margin-bottom: 0.1rem;
      .set-size(6.42rem, 2.53rem);
    }

    &__line4 {
      .flex-type();

      &__item {
        .set-size(1.22rem, 2.58rem);

        &:not(:last-child) {
          margin-right: 0.08rem;
        }
      }
    }
  }

  &__text {
    margin-top: 0.5rem;
    font-size: 0.28rem;
    line-height: 0.56rem;
    color: @text-color;
  }

  &__img2 {
    width: 100%;
    margin-top: 0.8rem;

    &__item {
      .set-size(6.42rem, 3.32rem);

      &:nth-child(1) {
        margin-bottom: 0.1rem;
      }
    }
  }
}

.local {
  width: 100%;
  .flex-type(center,column);

  &__img {
    max-width: 6.42rem;
    width: 6.42rem;

    &:not(:last-child) {
      margin-bottom: 0.1rem;
    }
  }

  &__text {
    margin-top: 0.5rem;
    font-size: 0.28rem;
    line-height: 0.56rem;
    color: @text-color;
  }
}

.news {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0.32rem 0.3rem;
  .flex-type(center,column);

  &__title {
    width: 100%;
    margin: 0.64rem 0 0.3rem 0;
    font-size: 0.36rem;
    font-weight: 700;
    color: @text-color;
    line-height: 0.5rem;
  }

  &__list {
    &__item {
      margin-top: 0.3rem;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);