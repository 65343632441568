@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.organization {
  box-sizing: border-box;
  padding: 0.8rem 0.54rem 1rem;
  .set-size(100%);
  .flex-type(center, column);

  img {
    max-width: 6.42rem;
  }
}

.title {
  width: 100%;
  margin-bottom: 0.48rem;
  font-size: 0.36rem;
  font-weight: 700;
  color: @text-color;
  line-height: 0.5rem;
}

.portion {
  margin-top: 0.8rem;
  .flex-type(center, column);
}

.president {
  .flex-type(center, column);

  &__item {
    width: 100%;

    &:nth-child(n + 2) {
      margin-bottom: 0.8rem;
    }

    &__info {
      .flex-type();
    }

    &__avatar {
      margin-right: 0.52rem;
      .set-size(2.16rem);
    }

    &__name {
      font-size: 0.36rem;
      font-weight: 700;
      color: @primary-color;
      line-height: 0.5rem;
    }

    &__rank {
      font-size: 0.28rem;
      color: @primary-color;
      line-height: 0.36rem;
    }

    &__profile {
      margin-top: 0.46rem;
      font-size: 0.28rem;
      color: @text-color;
      line-height: 0.56rem;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);