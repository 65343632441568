@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.title {
  width: 100%;
  margin-bottom: 0.48rem;
  font-size: 0.36rem;
  font-weight: 700;
  color: @text-color;
  text-align: center;
  line-height: 0.56rem;
}

.profile {
  width: 100%;
  margin-bottom: 0.32rem;
  font-size: 0.28rem;
  color: @text-color;
  line-height: 0.48rem;
}

.chapters {
  width: 100%;
  font-size: 0.28rem;
  color: @text-color;
  line-height: 0.48rem;

  &__title {
    margin-bottom: 0.32rem;
    font-weight: 700;
    text-align: center;
  }

  &__content {
    margin-bottom: 0.32rem;
    text-indent: 2em;

    &__sub {
      text-indent: 2em;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);