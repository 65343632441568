@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.contact {
  box-sizing: border-box;
  padding: 0.64rem 0.32rem 1rem;
  .set-size(100%);
  .flex-type(center, column);
}

.info {
  width: 100%;
  margin-top: 0.64rem;
  font-size: 0.28rem;
  color: @text-color;

  &__line {
    line-height: 0.84rem;

    & > span:nth-of-type(1) {
      display: inline-block;
      width: 1.36rem;
      text-align: left;
      color: @primary-color;
    }
  }
}

.address {
  &__item {
    &:nth-child(2) {
      margin-top: 0.48rem;
    }
  }

  &__line {
    display: flex;
    line-height: 0.56rem;
  }

  &__icon {
    .set-size(0.36rem, 0.48rem);
  }

  &__detail {
    margin: 0.08rem 0 0 0.4rem;

    & > div:nth-of-type(1) {
      font-size: 0.36rem;
    }
  }

  &__map {
    margin-top: 0.24rem;
    border-radius: 0.4rem;
    .set-size(6.86rem, 3.76rem);
  }
}

.sheet {
  .set-size(100%);
  .flex-type(center);
}

@primary-color: rgba(204, 140, 75, 1);