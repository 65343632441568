@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.service {
  box-sizing: border-box;
  padding: 0.48rem 0.54rem 1rem;
  .set-size(100%);
  .flex-type(center, column);

  img {
    max-width: 6.42rem;
  }
}

.title {
  width: 100%;
  margin-bottom: 0.48rem;
  font-size: 0.36rem;
  font-weight: 700;
  color: @text-color;
  line-height: 0.5rem;
}

.profile {
  width: 100%;

  &__img {
    margin-bottom: 0.48rem;
    .set-size(6.42rem,  5.35rem);
  }

  &__content {
    width: 100%;
    font-size: 0.28rem;
    color: @text-color;
    line-height: 0.56rem;

    & > div:nth-child(2) {
      margin-top: 0.56rem;
    }
  }
}

.base {
  margin-top: 0.96rem;

  &__list {
    width: 100%;
    margin-top: 0.48rem;
    font-size: 0.28rem;
    color: @text-color;
    line-height: 0.56rem;
  }
}

.plus {
  width: 100%;
  margin-top: 0.96rem;

  &__table {
    width: 100%;
    border-bottom: 0.06rem solid @primary-color;

    &__thead {
      height: 0.52rem;
      line-height: 0.52rem;
      background: linear-gradient(90deg, rgba(232, 169, 89, 1) 0%, rgba(127, 65, 32, 1) 100%);

      &__td {
        padding-left: 0.16rem;
        font-size: 0.28rem;
        font-weight: 700;
        color: @white-color;

        & > span:nth-child(1) {
          margin-right: 0.58rem;
        }
      }
    }

    &__tbody {
      &__tr {
        &:not(:last-child) {
          border-bottom: 1px solid@primary-color;
        }
      }

      &__td {
        .set-size(100%, 1.86rem);
        .flex-type(flex-start);

        & > span:nth-child(1) {
          display: inline-block;
          width: 2.04rem;
          font-size: 0.32rem;
          font-weight: 700;
          color: @primary-color;
        }

        & > span:nth-child(2) {
          display: inline-block;
          max-width: 4.39rem;
          font-size: 0.28rem;
          color: rgba(35, 24, 21, 1);
          vertical-align: middle;
        }
      }
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);