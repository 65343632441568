@import '../../assets/styles/abstracts/mixins.less';
@import '../../assets/styles/abstracts/function.less';
@import '../../assets/styles/abstracts/variables.less';
@import '../../assets/styles/abstracts/reset.less';
@import '../../assets/styles/abstracts/global.less';
.mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  .set-size(100%);
}

.sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  background-color: @white-color;
  border-radius: 0.5rem 0.5rem 0 0;
  font-size: 0.28rem;

  &__tips {
    .set-size(100%, 1.2rem);
    .flex-type(center);
  }

  &__item {
    border-bottom: 1px solid rgba(234, 233, 241, 1);
    .set-size(100%, 1rem);
    .flex-type(center);
  }

  &__cancel {
    border-top: 0.16rem solid rgba(234, 233, 241, 1);
    .set-size(100%, 1.2rem);
    .flex-type(center);
  }
}

@primary-color: rgba(204, 140, 75, 1);