@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
.city {
  box-sizing: border-box;
  padding-bottom: 0.8rem;
  background: @white-color;
  .set-size(100%);
  .flex-type(center,column);

  &__content {
    box-sizing: border-box;
    width: 100%;
    padding: 0 0.54rem;
    .flex-type(center,column);
  }
}

.subtitle {
  width: 100%;
  margin: 0.8rem 0 0.48rem 0;
  font-size: 0.36rem;
  font-weight: 700;
  line-height: 0.5rem;
  color: @text-color;
}

.article {
  width: 100%;
  font-size: 0.28rem;
  font-weight: 400;
  color: @text-color;
  line-height: 0.56rem;
}

.swiper {
  box-sizing: border-box;
  width: 100vw;
  padding-left: 0.54rem;

  &__ins {
    padding-bottom: 0.24rem;
  }

  &__img {
    .set-size(100%, 3.82rem);
  }
}

.profile {
  width: 100%;

  &__bg {
    max-width: 100vw;
    .set-size(100%, 5.62rem);
  }

  &__info {
    padding: 0.8rem 0.54rem;
    background: linear-gradient(226deg, rgba(89, 146, 255, 1) 0%, rgba(48, 98, 242, 1) 100%);
    .set-size(100%, 100%);
  }

  &__title {
    width: 100%;
    text-align: center;
    font-size: 0.48rem;
    font-weight: 700;
    line-height: 0.66rem;
    color: @white-color;
  }

  &__text {
    margin-top: 0.56rem;
    font-size: 0.28rem;
    line-height: 0.56rem;
    color: @white-color;
  }
}

.brief {
  width: 100%;
  margin-top: 0.68rem;
  .flex-type(center, column);

  &__logo {
    margin-bottom: 0.7rem;
    .flex-type();

    &__item {
      height: 0.52rem;

      &:not(:last-child) {
        margin-right: 0.7rem;
      }
    }
  }

  &__img {
    width: 100%;
    margin-bottom: 0.48rem;
    flex-wrap: wrap;
    .flex-type();

    &__large {
      .set-size(100%, 3.68rem);
    }

    &__item {
      .set-size(50%, 2.64rem);
    }
  }
}

.list {
  width: 100%;
  .flex-type(center,column);

  &__list {
    font-size: 0.28rem;
    line-height: 0.6rem;
    color: @text-color;
  }

  &__item {
    margin-bottom: 0.3rem;

    &:last-child {
      margin-bottom: 0;
    }

    &__label {
      font-size: 0.32rem;
      font-weight: 700;
      color: @primary-color;
    }
  }
}

.park {
  width: 100%;
  .flex-type(center, column);
}

@primary-color: rgba(204, 140, 75, 1);