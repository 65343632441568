@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.chain {
  box-sizing: border-box;
  padding: 0.12rem 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    width: 12rem;
    background: @white-color;
    padding-bottom: 0.5rem;
    .flex-type(center, column);
  }
}

.profile {
  position: relative;
  display: flex;
  width: 8.72rem;
  margin-top: 0.93rem;

  &__logo {
    .set-size(3.59rem, 1.2rem);
  }

  &__text {
    width: 4.45rem;
    margin: 0.08rem 0 0 0.55rem;
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: @text-color;
  }
}

.content {
  width: 100%;
  margin-top: 0.92rem;
  .flex-type(center, column);

  &__photo {
    .set-size(100%, 3.5rem);
  }

  &__cooperator {
    margin: 0.65rem 0;
    .set-size(8.16rem, 1.2rem);
  }

  &__image {
    margin: 0.65rem 0 0.5rem 0;
    .set-size(100%, 2.32rem);
  }

  &__text {
    width: 8.45rem;
    font-size: 0.16rem;
    color: @text-color;
    line-height: 0.3rem;
  }
}

@primary-color: rgba(204, 140, 75, 1);