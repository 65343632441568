@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
.news {
  background-color: @white-color;
  .set-size(100%);
  .flex-type(center, column);
}

.detail {
  width: 100%;
  box-sizing: border-box;
  padding: 0.48rem 0.54rem 0.8rem;

  border-bottom: 0.16rem solid rgba(249, 249, 249, 1);
  .flex-type(center, column);

  &__title {
    width: 100%;
    font-size: 0.4rem;
    font-weight: 700;
    color: @text-color;
    line-height: 0.64rem;
    .ellipsis-multiple(2);
  }

  &__time {
    width: 100%;
    margin-top: 0.32rem;
    font-size: 0.24rem;
    color: rgba(167, 167, 167, 1);
    line-height: 0.34rem;
  }

  &__article {
    width: 100%;
    margin-top: 0.64rem;
    font-size: 0.28rem;
    color: @text-color;
    line-height: 0.56rem;

    img {
      max-width: calc(100vw - 1.08rem) !important;
      height: auto !important;
      margin: 0.48rem 0;
    }
  }
}

.recommend {
  margin-top: 0.64rem;
  .flex-type(center, column);

  &__title {
    width: 100%;
    margin-bottom: 0.48rem;
    font-size: 0.32rem;
    font-weight: 700;
    color: @text-color;
    line-height: 0.5rem;
  }

  &__list {
    &__item {
      background-color: transparent;

      &--horizontal {
        margin-top: 0.3rem;
      }
    }
  }

  &__more {
    box-sizing: border-box;
    padding-left: 0.2rem;
    margin: 0.48rem;
    border-radius: 0.33rem;
    border: 1px solid @text-color-tertiary;
    font-size: 0.24rem;
    color: @text-color-tertiary;
    line-height: 0.56rem;
    cursor: pointer;
    .set-size(1.8rem, 0.66rem);
    .flex-type(center);
  }
}

.appendix {
  width: 100%;
  margin-top: 0.8rem;
  font-size: 0.28rem;
  line-height: 0.56rem;

  &__title {
    margin-bottom: 0.12rem;
    font-weight: 700;
    color: @text-color;
  }

  &__item {
    user-select: none;
    color: @primary-color;
    cursor: pointer;
    .ellipsis-multiple(1);
  }
}

@primary-color: rgba(204, 140, 75, 1);