// Suitable for webkit core and mobile
.ellipsis-multiple(@num: 1) {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @num;
}

// Set width && height
.set-size(@width, @height: @width) {
  width: @width;
  height: @height;
}

// flex layout
.flex-type(@justify-type: flex-start, @direction: row) {
  display: flex;
  flex-direction: @direction;
  justify-content: @justify-type;
  align-items: center;
}
