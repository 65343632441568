@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.footer {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5999;
  padding: 0.48rem 0.18rem 0.58rem 0.32rem;
  background: rgba(51, 51, 51, 1);
  font-size: 0.24rem;
  .set-size(100%, 10.32rem);

  &__label {
    margin-bottom: 0.28rem;
    font-weight: 700;
    line-height: 0.48rem;
    color: rgba(167, 167, 167, 1);
  }

  &__list {
    line-height: 0.56rem;
    color: rgba(167, 167, 167, 1);

    & a{
      
      color: rgba(167, 167, 167, 1);
    }

    &__line {
      display: flex;

      & > div:nth-of-type(1) {
        flex-shrink: 0;
        width: 1.08rem;
      }

      &__address {
        &:nth-child(2n) {
          margin-top: -0.14rem;
        }
      }
    }
  }

  &__info {
    &__line {
      display: flex;
      line-height: 0.28rem;
    }
  }

  &__code {
    margin-top: 0.48rem;
    .flex-type(flex-start, column);

    &:last-of-type {
      margin-left: 0.66rem;
    }

    &__img {
      margin-bottom: 0.06rem;
      .set-size(1.3rem);
    }
  }

  
  &__beian {
    &__img {
      margin-bottom: 2px;
      margin-right: 4px;
      .set-size(16px,17px);
    }
  }

  &__right {
    margin-top: 0.5rem;
  }
}

@primary-color: rgba(204, 140, 75, 1);