@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.local {
  box-sizing: border-box;
  padding: 0.8rem 0.54rem;
  .set-size(100%);
  .flex-type(center, column);

  img {
    max-width: 6.42rem;
  }
}

.title {
  width: 100%;
  margin-bottom: 0.48rem;
  font-size: 0.36rem;
  font-weight: 700;
  color: @text-color;
  line-height: 0.5rem;
}

.content {
  width: 100%;
  font-size: 0.28rem;
  color: @text-color;
  line-height: 0.56rem;
}

.case {
  margin-top: 0.66rem;

  &__item {
    position: relative;
    padding-bottom: 0.46rem;
    margin-bottom: 0.48rem;

    &::after {
      position: absolute;
      bottom: 0;
      left: -0.22rem;
      z-index: 0;
      background-color: rgba(230, 231, 232, 1);
      content: '';
      .set-size(6.86rem, 0.02rem);
    }
  }

  &__content {
    margin-top: 0.48rem;
  }

  &__img {
    &:not(:last-of-type) {
      margin-bottom: 0.16rem;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);