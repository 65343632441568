@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.interview {
  width: 100%;
  margin-top: 0.12rem;
  padding-bottom: 0.8rem;
  // .set-size(100%, 13rem);

  &__content {
    display: flex;
    justify-content: space-between;
    width: 12rem;
    margin-left: auto;
    margin-right: auto;
    // .set-size(12rem, 12.15rem);
  }
}

.other {
  margin-top: 0.68rem;
  padding: 0 0.32rem;
  font-size: 0.14rem;
  line-height: 0.2rem;
  .set-size(100%, 0.56rem);

  &__prev,
  &__next {
    cursor: pointer;
    color: @text-color-tertiary;
    .ellipsis-multiple(1);

    &:hover,
    &:active {
      color: @primary-color;
    }
  }

  &__next {
    margin-top: 0.16rem;
  }
}

.article {
  position: relative;
  overflow: hidden;
  width: 8.1rem;
  padding: 0.66rem 0.6rem 0.5rem;
  background: @white-color;
  // .set-size(8.1rem, 12.15rem);

  &__container {
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: @text-color;
    .set-size(100%);

    img {
      max-width: 6.3rem !important;
      height: auto !important;
      margin: 0.1rem 0;
    }

    &__title {
      width: 100%;
      margin-bottom: 0.42rem;
      text-align: center;
      font-size: 0.32rem;
      font-weight: 700;
      color: @text-color;
      line-height: 0.52rem;
    }

    &__content {
      box-sizing: border-box;
      width: 100%;
      padding: 0 0.32rem;
    }
  }
}

.previous {
  position: sticky;
  top: 1.48rem;
  width: 3.72rem;
  height: calc(100vh - 1.6rem);
  overflow: hidden;

  &__title {
    margin-bottom: 0.24rem;
    font-size: 0.24rem;
    font-weight: 700;
    line-height: 0.33rem;
    color: @text-color;
  }

  &__list {
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    border-radius: 0.08rem;
    // mask-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 0) 100%);
    .set-size(100%, calc(100% - 0.57rem));

    // &:hover {
    //   mask-image: none;
    // }

    &__item {
      margin-bottom: 0.16rem;
      .set-size(3.6rem, 1rem);
    }

    &::-webkit-scrollbar {
      display: block;
      width: 0.05rem;
    }

    &::-webkit-scrollbar-thumb {
      width: 0.05rem;
      height: 1.6rem;
      border-radius: 0.03rem;
      background: rgba(216, 216, 216, 1);
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);