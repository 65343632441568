@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.layout {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  padding-bottom: 10.32rem;
  font-family: PingFangSC-Regular, PingFang SC;

  &__content {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 1.08rem);
  }

  img {
    max-width: 100vw;
  }
}

:global(::-webkit-scrollbar) {
  display: none;
}

:global(.ant-image) {
  width: 100%;
  height: 100%;
}

:global(.ant-image-img) {
  width: 100%;
  height: 100%;
}

:global {
  :root body {
    overflow-x: hidden;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
  }
}

:global {
  .ant-btn,
  .ant-btn-primary {
    border: 0 !important;
  }

  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    color: @white-color !important;
    background: linear-gradient(
      90deg,
      rgba(252, 189, 109, 1) 0%,
      rgba(147, 85, 52, 1) 100%
    ) !important;
  }

  .ant-message {
    z-index: 10000 !important;
  }

  .ant-image-preview-wrap {
    z-index: 2000 !important;
  }
}

@primary-color: rgba(204, 140, 75, 1);