@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.trip {
  box-sizing: border-box;
  padding: 0 3.6rem 1.36rem;
  background: @white-color;
  .set-size(100%);
  .flex-type(center,column);

  &__content {
    width: 12rem;
    .flex-type(center,column);
  }
}

.subtitle {
  margin: 0.72rem 0 0.4rem 0;
  text-align: center;
  font-size: 0.32rem;
  font-weight: 700;
  line-height: 0.45rem;
  color: @text-color;
}

.subtext {
  width: 8.45rem;
  margin-top: 0.24rem;
  font-size: 0.16rem;
  color: @text-color;
  line-height: 0.3rem;
}

.profile {
  position: relative;
  z-index: 1;
  .set-size(12rem, 2.99rem);

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    .set-size(100%);
  }

  &__logo {
    margin: 0.5rem 0 0.41rem 0.6rem;
    .set-size(3.04rem, 0.47rem);
  }

  &__text {
    width: 4.25rem;
    margin-left: 0.6rem;
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: @text-color-tertiary;
  }
}

.other {
  width: 100%;
  .flex-type(center,column);

  &__img {
    width: 100%;

    &__line1 {
      margin-bottom: 0.06rem;
      .flex-type();

      &__item {
        max-height: 1.94rem;
        // .set-size(2.92rem, 1.97rem);

        &:nth-child(2) {
          .set-size(2.82rem, 1.94rem);
        }

        &:not(:last-child) {
          margin-right: 0.1rem;
        }
      }
    }

    &__line2 {
      .flex-type();

      &__large {
        margin-right: 0.1rem;
        // .set-size(6.01rem, 2.5rem);
        max-height: 2.5rem;
      }

      &__small {
        max-height: 2.5rem;
        // .set-size(1.12rem, 2.5rem);

        &:not(:last-child) {
          margin-right: 0.07rem;
        }
      }
    }
  }

  &__img2 {
    width: 100%;
    margin-top: 0.72rem;
    .flex-type();

    &__item {
      max-height: 3.14rem;
    }
  }
}

.local {
  width: 100%;
  .flex-type(center,column);

  &__img {
    width: 100%;
    .flex-type();

    &__item {
      max-height: 2.6rem;
    }
  }
}

.news {
  width: 100%;
  .flex-type(center,column);

  &__list {
    width: 100%;
    flex-wrap: wrap;
    .flex-type();

    &__item {
      margin: 0 0.57rem 0.46rem 0;
    }

    &__item:nth-child(3n) {
      margin-right: 0;
    }
  }

  &__more {
    font-size: 0.16rem;
    line-height: 0.22rem;
    color: @text-color-secondary;
    cursor: pointer;
    .flex-type(center,column);
  }
}

@primary-color: rgba(204, 140, 75, 1);