@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
.card {
  box-sizing: border-box;
  display: flex;
  padding-right: 0.22rem;
  border: 1px solid #e5e5e5;
  background: @white-color;
  .set-size(100%, 2.82rem);

  &__img {
    flex-shrink: 0;
    .set-size(2.82rem);
  }

  &__info {
    margin: 0.5rem 0 0 0.2rem;

    &__number {
      font-size: 0.36rem;
      color: rgba(207, 156, 110, 1);
      line-height: 0.38rem;
    }

    &__content {
      margin-top: 0.32rem;
      font-size: 0.32rem;
      color: @text-color;
      line-height: 0.56rem;
      .ellipsis-multiple(2);
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);