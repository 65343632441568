@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: @white-color;
  .set-size(100%, 1.08rem);
  .flex-type(space-between);

  &__logo {
    margin-left: 0.32rem;
    .set-size(2.6rem, 0.56rem);
  }

  &__icon {
    background-color: @white-color;
    .set-size(1.08rem);
    .flex-type(center, column);

    &__line {
      transition: 0.25s all;
      transform-origin: center center;
      background-color: @text-color;
      .set-size(0.34rem, 0.04rem);

      &:not(:last-of-type) {
        margin-bottom: 0.12rem;
      }

      &--cancel {
        &:nth-of-type(1) {
          transform: translateY(0.16rem) rotateZ(45deg);
        }

        &:nth-of-type(2) {
          opacity: 0;
        }

        &:nth-of-type(3) {
          transform: translateY(-0.16rem) rotateZ(-45deg);
        }
      }
    }
  }

  &__menu {
    position: fixed;
    top: 1.08rem;
    left: 0;
    z-index: -9999;
    width: 100%;
    height: 0;
    transition: 0.25s all ease;
    opacity: 0;
    background-color: @white-color;

    &--show {
      height: calc(100vh - 1.08rem);
      opacity: 1;
      z-index: 9999;
    }

    &__logo {
      position: fixed;
      left: 1.08rem;
      bottom: 0.8rem;
      .set-size(4.94rem, 1.1rem);
    }
  }

  &__instance {
    .set-size(100%, 1.08rem);
  }
}

@primary-color: rgba(204, 140, 75, 1);