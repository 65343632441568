@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
.news {
  position: relative;
  padding: 1.4rem 0.32rem 0.32rem;
  background: @white-color;
  .set-size(100%);
  .flex-type(center, column);

  &__tab {
    position: absolute;
    top: 0;
    left: 0;
    .set-size(100%, 1.08rem);

    &__ins {
      z-index: 2999;
      background: rgba(249, 249, 249, 1);
    }

    &__item {
      flex-shrink: 1;
      width: 100%;
      text-align: center;
      font-size: 0.24rem;
      color: rgba(138, 138, 138, 1);
    }

    &__active {
      font-size: 0.28rem;
      font-weight: 700;
      color: @primary-color;
    }
  }
}

.list {
  &__item {
    &--horizontal {
      margin-top: 0.3rem;
    }
  }
}

.affair {
  width: 100%;

  &__list__item {
    &:not(:first-child) {
      border-top: 0 !important;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);