@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.choice {
  box-sizing: border-box;
  .set-size(100%);
  .flex-type(center, column);

  img {
    max-width: 6.86rem;
  }

  &__tabs {
    width: 100%;

    &__ins {
      width: 100%;
      z-index: 3999;
      padding: 0 0.32rem;
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: auto;

      &::after {
        position: absolute;
        left: 0.32rem;
        bottom: 0;
        z-index: 0;
        background-color: rgba(217, 217, 217, 1);
        border-radius: 0.03rem;
        opacity: 0.43;
        backdrop-filter: blur(8px);
        content: '';
        .set-size(14.8rem, 0.06rem);
      }
    }

    &__item {
      position: relative;
      min-width: fit-content;
      height: 100%;
      font-size: 0.24rem;
      color: rgba(138, 138, 138, 1);

      &:not(:last-of-type) {
        margin-right: 0.42rem;
      }
    }

    &__active {
      position: relative;
      font-size: 0.28rem;
      font-weight: 700;
      color: @primary-color;

      &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        background-color: @primary-color;
        border-radius: 0.03rem;
        backdrop-filter: blur(8px);
        content: '';
        .set-size(100%, 0.06rem);
      }
    }
  }

  &__info {
    padding: 0.66rem 0.32rem 1.24rem;
    .flex-type(center);
  }

  &__img {
    width: 6.86rem;
  }
}

@primary-color: rgba(204, 140, 75, 1);