@import '../../assets/styles/abstracts/mixins.less';
@import '../../assets/styles/abstracts/function.less';
@import '../../assets/styles/abstracts/variables.less';
@import '../../assets/styles/abstracts/reset.less';
@import '../../assets/styles/abstracts/global.less';
.winner {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: @white-color;
  transition: all 0.25s ease-in-out;
  .set-size(100%);

  &--show {
    box-shadow: 0 0.04rem 0.27rem 0 @primary-color;
  }

  &--cursor {
    cursor: pointer;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    .set-size(100%);
  }

  &__info {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding-bottom: 0.16rem;
    transition: all 0.25s ease-in-out;
    text-align: center;
    opacity: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.88) 100%);
    .set-size(100%, 80%);
    .flex-type(flex-end, column);

    &--show {
      opacity: 1;
    }
  }

  &__name {
    transition: all 0.25s ease-in-out;
    transform: translateY(1rem);
    font-size: 0.24rem;

    &--show {
      transform: translateY(0);
    }
  }

  &__company {
    transition: all 0.25s ease-in-out;
    transform: translateY(1rem);
    font-size: 0.16rem;

    &--show {
      transform: translateY(0);
    }
  }

  &__icon {
    position: absolute;
    top: 38%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    .set-size(0.6rem);
  }

  &--mobile {
    .winner__info {
      padding-bottom: 0.34rem;
    }

    .winner__name {
      font-size: 0.32rem;
      line-height: 0.44rem;
    }

    .winner__company {
      font-size: 0.24rem;
      line-height: 0.44rem;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);