@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.service {
  box-sizing: border-box;
  padding: 0.12rem 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    width: 12rem;
    background: @white-color;
    padding-bottom: 0.5rem;
    .flex-type(center, column);
  }
}

.title {
  font-size: 0.32rem;
  font-weight: 700;
  text-align: center;
  color: @text-color;
  line-height: 0.45rem;
}

.profile {
  width: 8.45rem;
  margin-top: 0.98rem;

  &__info {
    display: flex;
    margin-top: 0.4rem;
  }

  &__img {
    .set-size(3.85rem, 3.18rem);
  }

  &__content {
    width: 3.84rem;
    margin-left: 0.41rem;
    font-size: 0.16rem;
    color: @text-color;
    line-height: 0.3rem;

    & > div:nth-child(2) {
      margin-top: 0.3rem;
    }
  }
}

.base {
  margin-top: 0.72rem;

  &__list {
    width: 8.45rem;
    margin-top: 0.38rem;
    font-size: 0.16rem;
    color: @text-color;
    line-height: 0.3rem;
  }
}

.plus {
  margin-top: 0.6rem;

  &__table {
    width: 8.45rem;
    margin-top: 0.24rem;
    border-bottom: 0.03rem solid @primary-color;

    &__thead {
      height: 0.27rem;
      background: linear-gradient(90deg, rgba(232, 169, 89, 1) 0%, rgba(127, 65, 32, 1) 100%);

      &__td {
        padding-left: 0.45rem;
        font-size: 0.16rem;
        font-weight: 700;
        color: @white-color;

        & > span:nth-child(1) {
          margin-right: 0.4rem;
        }
      }
    }

    &__tbody {
      &__tr {
        &:not(:last-child) {
          border-bottom: 1px solid @primary-color;
        }
      }

      &__td {
        width: 50%;
        height: 0.93rem;
        line-height: 0.93rem;
        padding: 0 0.24rem 0 0.45rem;
        .set-size(50%, 0.93rem);

        &--split {
          position: relative;

          &::after {
            position: absolute;
            top: 0.2rem;
            right: 0;
            background: @primary-color;
            content: '';
            .set-size(0.01rem, 0.54rem);
          }
        }

        & > span:nth-child(1) {
          display: inline-block;
          width: 1.04rem;
          font-size: 0.16rem;
          font-weight: 700;
          color: @primary-color;
          line-height: 0.22rem;
        }

        & > span:nth-child(2) {
          display: inline-block;
          max-width: 2.42rem;
          font-size: 0.14rem;
          color: rgba(35, 24, 21, 1);
          line-height: 0.22rem;
          vertical-align: middle;
        }
      }
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);