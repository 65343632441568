@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.branch {
  box-sizing: border-box;
  padding: 0 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    width: 12rem;
    background: @white-color;
    padding: 0.16rem 0 0.86rem;
    .flex-type(center, column);

    img {
      max-width: 8.45rem;
    }
  }
}

.title {
  margin-bottom: 0.26rem;
  font-size: 0.32rem;
  font-weight: 700;
  color: @text-color;
  line-height: 0.45rem;
}

.committee {
  width: 100%;
  margin-top: 0.82rem;
  .flex-type(center, column);

  &__swiper {
    .set-size(9.36rem, 3.42rem);
  }

  &__item {
    width: 9.36rem;
    .flex-type(center);

    &__ins {
      width: 9.2rem;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);