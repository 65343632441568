@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.standard {
  box-sizing: border-box;
  padding: 0.8rem 0.32rem 1rem;
  .set-size(100%);
  .flex-type(center, column);

  img {
    max-width: 6.86rem;
  }
}

.title {
  width: 100%;
  font-size: 0.36rem;
  font-weight: 700;
  color: @text-color;
  line-height: 0.5rem;
}

.dues {
  width: 100%;

  &__table {
    margin-top: 0.48rem;
    border-top: 0.04rem solid @primary-color;
    border-bottom: 0.04rem solid @primary-color;

    &__line {
      padding: 0 0.32rem;
      .flex-type();

      &:nth-of-type(1) {
        padding: 0.38rem 0.32rem;
        border-bottom: 1px solid @primary-color;

        & > div:nth-child(1) {
          border-right: 1px solid @primary-color;
        }
      }

      &__type {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 2.24rem;
        font-size: 0.28rem;
        color: @primary-color;

        &__img {
          margin-bottom: 0.24rem;
          .set-size(1.2rem);
        }
      }

      &__content {
        width: 100%;
        font-size: 0.28rem;
        color: @text-color;
        .flex-type(center, column);

        &__item {
          position: relative;
          width: 100%;
          min-height: 3.36rem;
          .flex-type(center, column);

          &:last-child {
            border-top: 1px solid @primary-color;
          }

          &::after {
            position: absolute;
            top: 0.24rem;
            left: 0;
            z-index: 0;
            background-color: @primary-color;
            content: '';
            .set-size(0.02rem, 2.86rem);
          }

          &__img {
            .set-size(1.06rem, 0.92rem);
          }

          &__tips {
            margin: 0.12rem 0 0.26rem 0;
            color: rgba(138, 138, 138, 1);
          }

          &__quota {
            font-size: 0.24rem;
          }
        }
      }
    }
  }
}

.process {
  margin-top: 0.8rem;

  &__img {
    margin-top: 0.48rem;
  }
}

.require {
  width: 100%;
  margin-top: 0.8rem;

  &__title {
    width: 100%;
    margin-bottom: 0.32rem;
    .flex-type(space-between);

    &__more {
      flex-shrink: 0;
      width: fit-content;
      font-size: 0.24rem;
      color: rgba(167, 167, 167, 1);
      line-height: 0.56rem;
      text-align: right;
      .flex-type();
    }
  }

  &__list {
    &__item {
      font-size: 0.28rem;
      line-height: 0.8rem;
      color: @text-color;

      &__number {
        color: @primary-color;
      }
    }
  }
}

.become {
  width: 100%;
  margin-top: 0.6rem;

  &__list {
    margin-top: 0.8rem;

    &__item {
      font-size: 0.28rem;
      color: rgba(138, 138, 138, 1);
      line-height: 0.48rem;
      .flex-type();

      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }

      &__img {
        flex-shrink: 0;
        margin-right: 0.4rem;
        .set-size(2.32rem);
      }
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);