@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
@active-color: rgba(210, 153, 84, 1);

.tab {
  background: rgba(255, 255, 255, 0.68);
  font-size: 0.16rem;
  color: @text-color;
  line-height: 0.5rem;
  backdrop-filter: blur(0.08rem);
  .set-size(100%, 0.5rem);
  .flex-type(center);

  &__item {
    position: relative;

    &--active {
      color: @active-color;
    }

    &--active::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.04rem;
      background: @active-color;
      content: '';
    }

    &:hover {
      color: @active-color;
      cursor: pointer;
    }

    &--margin {
      &:not(:last-child) {
        margin-right: 0.8rem;
      }
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);