@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
.service {
  width: 100%;

  &__tab {
    position: sticky;
    left: 0;
    top: 0.86rem;
    z-index: 10;
    background: rgba(255, 255, 255, 0.68);
    backdrop-filter: blur(0.08rem);
  }
}

.profile {
  position: relative;
  z-index: 1;
  color: @white-color;
  .set-size(100%, 2.9rem);
  .flex-type(center);

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    object-position: center;
    .set-size(100%);
  }

  &__container {
    .set-size(12rem, 100%);
    .flex-type();
  }

  &__title {
    font-size: 0.18rem;
    line-height: 0.25rem;

    &__text {
      font-size: 0.6rem;
      font-weight: 700;
      line-height: 0.84rem;
    }
  }

  &__text {
    width: 6.15rem;
    margin-left: 1.69rem;
    font-size: 0.14rem;
    line-height: 0.3rem;
  }
}

@primary-color: rgba(204, 140, 75, 1);