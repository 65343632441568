@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.interview {
  width: 100%;
  padding-bottom: 1.28rem;
  background-color: @white-color;
}

.article {
  position: relative;
  overflow: hidden;
  padding: 0.48rem 0.32rem 1.28rem;
  border-bottom: 0.16rem solid rgba(249, 249, 249, 1);
  font-size: 0.28rem;
  line-height: 0.56rem;
  color: @text-color;

  img {
    max-width: 6.42rem;
    margin: 0.5rem 0;
  }

  &__title {
    width: 100%;
    font-size: 0.4rem;
    font-weight: 700;
    color: @text-color;
    line-height: 0.72rem;
    .ellipsis-multiple(3);
  }

  &__number {
    width: 100%;
    margin: 0.28rem 0 0.64rem 0;
    font-size: 0.24rem;
    color: rgba(167, 167, 167, 1);
    line-height: 0.34rem;
  }

  &__detail {
    img {
      max-width: calc(100vw - 0.64rem) !important;
      height: auto !important;
    }
  }
}

.previous {
  width: 100%;
  margin-top: 0.64rem;
  padding: 0 0.32rem;
  .flex-type(center, column);

  &__title {
    margin-bottom: 0.52rem;
    font-size: 0.36rem;
    font-weight: 700;
    line-height: 0.5rem;
    color: @text-color;
  }

  &__list {
    width: 100%;

    &__item {
      margin-bottom: 0.48rem;
      .set-size(100%, 2.82rem);
    }
  }

  &__more {
    box-sizing: border-box;
    padding-left: 0.2rem;
    border-radius: 0.33rem;
    border: 1px solid @text-color-tertiary;
    font-size: 0.24rem;
    color: @text-color-tertiary;
    line-height: 0.56rem;
    .set-size(1.8rem, 0.66rem);
    .flex-type(center);
  }
}

@primary-color: rgba(204, 140, 75, 1);