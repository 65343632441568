@import '../../assets/styles/abstracts/mixins.less';
@import '../../assets/styles/abstracts/function.less';
@import '../../assets/styles/abstracts/variables.less';
@import '../../assets/styles/abstracts/reset.less';
@import '../../assets/styles/abstracts/global.less';
.loading {
  &--mobile {
    padding: 0.32rem 0;
    .flex-type(center);

    .loading__ins {
      position: relative;
      border-radius: 50%;
      background: conic-gradient(@primary-color, @white-color);
      mask-image: radial-gradient(closest-side, transparent 66.7%, black 70%);
      animation: spin 1s linear infinite reverse;
      .set-size(0.36rem);

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: calc(50% - 0.03rem);
        border-radius: 50%;
        background-color: @primary-color;
        .set-size(0.06rem);
      }
    }

    & > span {
      margin-left: 0.2rem;
      font-size: 0.26rem;
      color: @text-color-tertiary;
      line-height: 0.56rem;
    }
  }

  &--pc {
    display: flex !important;
    width: fit-content !important;
    padding: 0.32rem 0;
    .flex-type(center);

    .loading__ins {
      position: relative;
      border-radius: 50%;
      background: conic-gradient(@primary-color, @white-color);
      mask-image: radial-gradient(closest-side, transparent 66.7%, black 70%);
      animation: spin 1s linear infinite reverse;
      .set-size(0.24rem);

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: calc(50% - 0.02rem);
        border-radius: 50%;
        background-color: @primary-color;
        .set-size(0.04rem);
      }
    }

    & > span {
      margin-left: 0.14rem;
      font-size: 0.16rem;
      color: @text-color-tertiary;
      line-height: 0.28rem;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@primary-color: rgba(204, 140, 75, 1);