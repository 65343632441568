@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
.card {
  box-sizing: border-box;
  padding: 0rem 0.16rem 0.14rem;
  border-radius: 0.08rem;
  background: @white-color;
  transition: all 0.25s;
  cursor: pointer;
  .set-size(2.43rem, 3.22rem);
  .flex-type(center, column);

  &:hover {
    box-shadow: 0 0.12rem 0.12rem 0 rgba(244, 223, 188, 1);
  }

  &__img {
    .set-size(2.02rem);
  }

  &__number {
    width: 100%;
    margin: 0.16rem 0 0.07rem 0;
    font-size: 0.28rem;
    color: rgba(207, 156, 110, 1);
    line-height: 0.28rem;
  }

  &__content {
    width: 100%;
    font-size: 0.18rem;
    color: @text-color;
    line-height: 0.28rem;
    .ellipsis-multiple(2);
  }
}

@primary-color: rgba(204, 140, 75, 1);