@import '../../assets/styles/abstracts/mixins.less';
@import '../../assets/styles/abstracts/function.less';
@import '../../assets/styles/abstracts/variables.less';
@import '../../assets/styles/abstracts/reset.less';
@import '../../assets/styles/abstracts/global.less';
.judge {
  z-index: 1;

  &--pc {
    .judge__avatar {
      border-radius: 50%;
      .set-size(1.26rem);
    }

    .judge__name {
      font-size: 0.25rem;
      font-weight: 400;
      color: rgba(224, 178, 132, 1);
      line-height: 0.36rem;
    }

    &.judge--horizontal {
      .flex-type();

      .judge__name {
        margin: 0 0.24rem;
      }

      .judge__rank {
        font-size: 0.25rem;
        color: rgba(224, 178, 132, 1);
        line-height: 0.36rem;
      }
    }

    &.judge--vertical {
      .flex-type(center, column);

      .judge__name {
        margin: 0.16rem 0 0.12rem 0;
      }

      .judge__rank {
        font-size: 0.14rem;
        color: rgba(217, 217, 217, 1);
        line-height: 0.24rem;
        .flex-type(center, column);
      }
    }
  }

  &--mobile {
    .judge__name {
      font-size: 0.36rem;
      font-weight: 700;
      color: @primary-color;
      line-height: 0.5rem;
    }

    &.judge--horizontal {
      .flex-type();

      .judge__avatar {
        border-radius: 50%;
        .set-size(2rem);
      }

      .judge__name {
        margin: 0 0.2rem 0 0.5rem;
      }

      .judge__rank {
        font-size: 0.36rem;
        font-weight: 400;
        color: @primary-color;
        line-height: 0.5rem;
      }
    }

    &.judge--vertical {
      height: 4rem;
      .flex-type(flex-start, column);

      .judge__avatar {
        border-radius: 50%;
        .set-size(1.8rem);
      }

      .judge__name {
        margin: 0.4rem 0 0.12rem 0;
      }

      .judge__rank {
        width: 3rem;
        font-size: 0.2rem;
        text-align: center;
        color: rgba(217, 217, 217, 1);
        line-height: 0.36rem;
        transform: scale(0.83);
        .flex-type(center, column);
      }
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);