@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.tank {
  box-sizing: border-box;
  padding: 0.12rem 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    width: 12rem;
    background: @white-color;
    padding: 0 1.77rem 0.5rem;
    .flex-type(center, column);

    img {
      max-width: 8.45rem;
    }
  }
}

.title {
  margin-bottom: 0.4rem;
  font-size: 0.32rem;
  font-weight: 700;
  text-align: center;
  color: @text-color;
  line-height: 0.45rem;
}

.content {
  width: 8.45rem;
  font-size: 0.16rem;
  color: @text-color;
  line-height: 0.3rem;
}

.profile {
  width: 8.45rem;
  margin-top: 1.08rem;
  .flex-type(center, column);

  &__logo {
    margin-bottom: 0.8rem;
    .set-size(5.03rem, 0.45rem);
  }

  &__img {
    margin-top: 0.38rem;
    .set-size(100%, 2.96rem);
  }
}

.initiator {
  width: 8.45rem;
  margin-top: 0.72rem;
  .flex-type(center, column);

  &__list {
    font-size: 0.14rem;
    color: @text-color;
    line-height: 0.3rem;
    flex-wrap: wrap;
    .flex-type();

    &__item {
      width: 1.96rem;
      margin-right: 0.2rem;

      &:nth-child(4n) {
        margin: 0;
      }
    }
  }
}

.structure {
  width: 8.45rem;
  margin-top: 0.72rem;
  .flex-type(center, column);

  &__list {
    width: 100%;
    margin-top: 0.38rem;
    .flex-type(space-between);
  }

  &__item {
    min-height: 2.76rem;
    .flex-type(flex-start, column);

    &:not(:last-child) {
      margin-right: 0.9rem;
    }

    &__avatar {
      margin-bottom: 0.16rem;
      border-radius: 50%;
      .set-size(1.2rem);
    }

    &__rank {
      font-size: 0.14rem;
      color: @primary-color;
      line-height: 0.2rem;
    }

    &__name {
      font-size: 0.25rem;
      color: @primary-color;
      line-height: 0.3rem;
    }

    &__appellation {
      margin-top: 0.12rem;
      font-size: 0.14rem;
      text-align: center;
      color: @text-color;
      line-height: 0.24rem;
    }
  }
}

.activity {
  width: 8.45rem;
  margin-top: 0.72rem;
  .flex-type(center, column);

  &__img {
    margin: 0.24rem 0;

    &:nth-of-type(2),
    &:nth-of-type(3) {
      margin-top: 0.72rem;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);