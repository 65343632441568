@import '../../../../assets/styles/abstracts/mixins.less';
@import '../../../../assets/styles/abstracts/function.less';
@import '../../../../assets/styles/abstracts/variables.less';
@import '../../../../assets/styles/abstracts/reset.less';
@import '../../../../assets/styles/abstracts/global.less';
.city {
  box-sizing: border-box;
  padding-bottom: 2rem;
  background: @white-color;
  .set-size(100%);
  .flex-type(center,column);

  &__content {
    .set-size(12rem, 100%);
    .flex-type(center,column);
  }
}

.subtitle {
  margin: 0.72rem 0 0.4rem 0;
  text-align: center;
  font-size: 0.32rem;
  font-weight: 700;
  line-height: 0.45rem;
  color: @text-color;
}

.article {
  width: 8.45rem;
  font-size: 0.16rem;
  color: @text-color;
  line-height: 0.3rem;
}

.swiper {
  width: 12rem;

  &__ins {
    padding-bottom: 0.24rem;
  }

  & &__img {
    cursor: pointer;
    .set-size(100%, 3.24rem);
  }
}

.profile {
  position: relative;
  z-index: 0;
  .set-size(100%, 2.9rem);
  .flex-type(center);

  &__info {
    // background: linear-gradient(131deg, rgba(89, 146, 255, 1) 0%, rgba(48, 98, 242, 1) 100%);
    .set-size(12rem, 100%);
  }

  &__title {
    margin-top: 0.42rem;
    font-size: 0.6rem;
    font-weight: 700;
    line-height: 0.84rem;
    color: @white-color;
  }

  &__text {
    width: 5rem;
    margin-top: 0.22rem;
    font-size: 0.14rem;
    line-height: 0.3rem;
    color: @white-color;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    object-position: center;
    .set-size(100%);
  }
}

.brief {
  margin-top: 0.4rem;
  .flex-type(center, column);

  &__logo {
    margin-bottom: 0.4rem;
    .flex-type();

    &__item {
      height: 0.47rem;

      &:not(:last-child) {
        margin-right: 0.7rem;
      }
    }
  }

  &__img {
    width: 12rem;
    margin-bottom: 0.23rem;
    .flex-type();

    &__item {
      max-height: 2.86rem;
    }
  }
}

.env,
.activity {
  .flex-type(center, column);
}

.process,
.policy {
  width: 8.45rem;
  .flex-type(center,column);

  &__list {
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: @text-color;
  }

  &__item {
    margin-bottom: 0.3rem;

    &:last-child {
      margin-bottom: 0;
    }

    &__label {
      font-size: 0.16rem;
      color: @primary-color;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);