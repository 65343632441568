@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.card {
  box-sizing: border-box;
  padding: 0.17rem 0.15rem 0.12rem;
  background: @white-color;
  cursor: pointer;
  font-size: 0.18rem;
  color: @text-color;
  transition: all 0.25s;
  .set-size(3.62rem, 3.37rem);
  .flex-type(flex-start, column);

  &:hover {
    box-shadow: 0 0.16rem 0.16rem 0 rgba(247, 234, 213, 1);
  }

  &__img {
    .set-size(3.28rem, 2.05rem);
  }

  &__title {
    flex-shrink: 0;
    margin: 0.14rem 0 0.04rem 0;
    .set-size(100%, 0.56rem);
    .ellipsis-multiple(2);
  }

  &__time {
    width: 100%;
    font-size: 0.16rem;
    color: @text-color-tertiary;
    line-height: 0.28rem;
  }
}

@primary-color: rgba(204, 140, 75, 1);