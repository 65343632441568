@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.group {
  box-sizing: border-box;
  padding: 0.12rem 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    width: 12rem;
    background: @white-color;
    padding-bottom: 0.5rem;
    .flex-type(center, column);

    img {
      max-width: 8.45rem;
    }
  }
}

.profile {
  position: relative;
  width: 8.45rem;
  margin-top: 0.87rem;

  &__introduce {
    position: relative;
    display: flex;
    .set-size(8.45rem, 2.41rem);

    &__logo {
      position: absolute;
      top: -0.6rem;
      left: -0.89rem;
      .set-size(6.28rem, 3.02rem);
    }

    &__text {
      position: absolute;
      top: 0;
      right: 0;
      width: 3.32rem;
      font-size: 0.16rem;
      line-height: 0.3rem;
      color: @text-color;
    }
  }

  &__info {
    margin-top: 0.28rem;
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: @text-color;
  }
}

.publish {
  width: 8.45rem;
  margin-top: 0.28rem;
  .flex-type(center, column);

  &__title {
    font-size: 0.18rem;
    font-weight: 700;
    color: @primary-color;
    line-height: 0.3rem;
  }

  &__cover {
    .set-size(8.45rem, 2.59rem);
    .flex-type(center);

    &__item {
      position: relative;
      opacity: 0;

      &:nth-child(1) {
        z-index: 4;
        transform: translateX(100%) scale(0.7);
      }

      &:nth-child(2) {
        z-index: 6;
        transform: translateX(60%) scale(0.8);
      }

      &:nth-child(3) {
        z-index: 8;
        transform: translateX(25%) scale(0.9);
      }

      &:nth-child(4) {
        z-index: 10;
      }

      &:nth-child(5) {
        z-index: 8;
        transform: translateX(-25%) scale(0.9);
      }

      &:nth-child(6) {
        z-index: 6;
        transform: translateX(-60%) scale(0.8);
      }

      &:nth-child(7) {
        z-index: 4;
        transform: translateX(-100%) scale(0.7);
      }

      & > img {
        max-height: 2.3rem;
        border: 1px solid rgba(235, 235, 235, 1);
      }
    }

    &__item--anim {
      @keyframes show {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      &:nth-child(1) {
        animation: show 0.4s 0.9s forwards ease-in;
      }

      &:nth-child(2) {
        animation: show 0.4s 0.6s forwards ease-in;
      }

      &:nth-child(3) {
        animation: show 0.4s 0.3s forwards ease-in;
      }

      &:nth-child(4) {
        animation: show 0.5s forwards cubic-bezier(1, 0, 1, 1);
      }

      &:nth-child(5) {
        animation: show 0.4s 0.3s forwards ease-in;
      }

      &:nth-child(6) {
        animation: show 0.4s 0.6s forwards ease-in;
      }

      &:nth-child(7) {
        animation: show 0.4s 0.9s forwards ease-in;
      }
    }

    &__reflect {
      position: absolute;
      bottom: -100%;
      left: 0;
      transform: rotateZ(180deg) rotateY(180deg);
      mask-image: linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 15%);
    }
  }

  &__list {
    width: 7.24rem;
    z-index: 12;
    padding: 0.1rem 0 0 0.1rem;
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: @text-color;

    &__item {
      width: fit-content;
      cursor: pointer;

      &:hover {
        color: @primary-color;
      }
    }
  }
}

.process {
  width: 8.45rem;
  margin-top: 0.3rem;
  .flex-type(center, column);

  &__title {
    font-size: 0.32rem;
    font-weight: 700;
    line-height: 0.45rem;
    color: @text-color;
  }

  &__img {
    margin-top: 0.3rem;
  }

  &__info {
    margin-top: 0.3rem;
    background: linear-gradient(274deg, #e0a55a 0%, #7b3f20 100%);
    .flex-type();

    &__item {
      font-size: 0.18rem;
      color: @white-color;
      line-height: 0.18rem;
      .set-size(1.2rem, 0.4rem);
      .flex-type(center);
    }
  }

  &__button {
    margin-top: 0.6rem;
    .flex-type(center);

    &__item {
      background: linear-gradient(90deg, rgba(232, 169, 89, 1) 0%, rgba(127, 65, 32, 1) 100%);
      border-radius: 0.23rem;
      font-size: 0.24rem;
      color: @white-color;
      line-height: 0.33rem;
      .set-size(3.36rem, 0.45rem);

      &:last-child {
        margin-left: 0.28rem;
      }
    }
  }

  &__tips {
    box-sizing: border-box;
    width: 100%;
    padding-left: 3em;
    margin-top: 0.18rem;
    text-indent: -3em;
    font-size: 0.14rem;
    line-height: 0.3rem;
    color: rgba(167, 104, 47, 1);
  }
}

.modal-content {
  width: 2.8rem;
  font-size: 0.14rem;
  line-height: 0.24rem;

  & > span {
    cursor: pointer;
    color: rgba(76, 122, 226, 1);
  }
}

@primary-color: rgba(204, 140, 75, 1);