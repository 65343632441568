@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.introduction {
  box-sizing: border-box;
  padding: 0 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    width: 12rem;
    background: @white-color;
    padding-bottom: 0.74rem;
    .flex-type(center, column);
  }

  &__img {
    margin: 0.38rem 0;
    .set-size(8.46rem, 6.9rem);
  }

  &__content {
    width: 8.1rem;
    font-size: 0.16rem;
    color: @text-color;
    line-height: 0.32rem;
  }
}

.logo {
  position: relative;
  width: 100%;
  margin-top: 0.98rem;
  z-index: 0;
  .flex-type(center);

  &__bg {
    position: absolute;
    top: 0.15rem;
    left: 0;
    z-index: -1;
    .set-size(12rem, 0.87rem);
  }

  &__icon {
    .set-size(1.58rem);
  }
}

.list {
  width: 8.1rem;
  margin-top: 0.72rem;

  &__label {
    font-size: 0.18rem;
    font-weight: 700;
    color: @primary-color;
    line-height: 0.32rem;

    &:nth-child(n + 2) {
      margin-top: 0.32rem;
    }
  }

  &__content {
    font-size: 0.16rem;
    color: @text-color;
    line-height: 0.32rem;
    // 解析换行
    white-space: pre-wrap;
  }
}

@primary-color: rgba(204, 140, 75, 1);