@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.choice {
  box-sizing: border-box;
  padding: 0.12rem 0 0.5rem 0;
  .set-size(100%);
  .flex-type(center, column);

  &__container {
    width: 12rem;
    background: @white-color;
    padding: 0.28rem 0 0.5rem 0;
    .flex-type(center, column);
  }

  &__tabs {
    box-sizing: border-box;
    width: 12rem;
    padding: 0 1rem;
    .flex-type(space-between);
  }

  &__info {
    padding: 0.52rem 0.91rem 0.79rem;
    border-top: 1px solid rgba(217, 217, 217, 1);
    .flex-type(center);
  }

  &__img {
    width: 10.18rem;
  }
}

@primary-color: rgba(204, 140, 75, 1);