@import '../../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../../assets/styles/abstracts/function.less';
@import '../../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../../assets/styles/abstracts/global.less';
.group {
  box-sizing: border-box;
  padding: 0.48rem 0 2rem;
  .set-size(100%);
  .flex-type(center, column);

  &__button {
    z-index: 22;
    .set-size(100%, 1.2rem);
    .flex-type(center);

    &--fixed {
      position: fixed;
      bottom: 0;
      left: 0;
    }

    &--absolute {
      position: absolute;
      bottom: 0.4rem;
      left: 0;
    }

    &__item {
      background: linear-gradient(90deg, rgba(232, 169, 89, 1) 0%, rgba(127, 65, 32, 1) 100%);
      font-size: 0.4rem;
      color: @white-color;
      line-height: 0.56rem;
      .set-size(3.4rem, 0.88rem);

      &:nth-child(1) {
        border-radius: 0.44rem 0 0 0.44rem;
      }

      &:nth-child(2) {
        margin-left: 0.06rem;
        border-radius: 0 0.44rem 0.44rem 0;
      }
    }
  }
}

.profile {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 0 0.54rem;

  &__logo {
    .set-size(6.1rem, 3.9rem);
  }

  &__info {
    margin-top: 0.5rem;
    font-size: 0.28rem;
    line-height: 0.56rem;
    color: @text-color;
  }
}

.publish {
  width: 100%;
  margin-top: 0.48rem;
  .flex-type(center, column);

  &__title {
    width: 100%;
    padding: 0 0.54rem;
    font-size: 0.32rem;
    font-weight: 700;
    line-height: 0.6rem;
    color: @primary-color;
  }

  &__cover {
    .set-size(7.5rem, 2.94rem);
    .flex-type(center);

    &__item {
      position: relative;
      opacity: 0;

      &:nth-child(1) {
        z-index: 4;
        transform: translateX(135%) scale(0.7);
      }

      &:nth-child(2) {
        z-index: 6;
        transform: translateX(80%) scale(0.8);
      }

      &:nth-child(3) {
        z-index: 8;
        transform: translateX(35%) scale(0.9);
      }

      &:nth-child(4) {
        z-index: 10;
      }

      &:nth-child(5) {
        z-index: 8;
        transform: translateX(-35%) scale(0.9);
      }

      &:nth-child(6) {
        z-index: 6;
        transform: translateX(-80%) scale(0.8);
      }

      &:nth-child(7) {
        z-index: 4;
        transform: translateX(-135%) scale(0.7);
      }

      & > img {
        max-height: 2.4rem;
        border: 1px solid rgba(235, 235, 235, 1);
      }
    }

    &__item--anim {
      @keyframes show {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      &:nth-child(1) {
        animation: show 0.4s 0.9s forwards ease-in;
      }

      &:nth-child(2) {
        animation: show 0.4s 0.6s forwards ease-in;
      }

      &:nth-child(3) {
        animation: show 0.4s 0.3s forwards ease-in;
      }

      &:nth-child(4) {
        animation: show 0.5s forwards cubic-bezier(1, 0, 1, 1);
      }

      &:nth-child(5) {
        animation: show 0.4s 0.3s forwards ease-in;
      }

      &:nth-child(6) {
        animation: show 0.4s 0.6s forwards ease-in;
      }

      &:nth-child(7) {
        animation: show 0.4s 0.9s forwards ease-in;
      }
    }

    &__reflect {
      position: absolute;
      bottom: -100%;
      left: 0;
      transform: rotateZ(180deg) rotateY(180deg);
      mask-image: linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 15%);
    }
  }

  &__list {
    width: 100%;
    z-index: 20;
    padding: 0 0.32rem 0 0.5rem;
    font-size: 0.28rem;
    line-height: 0.56rem;
    color: @text-color;

    &__item {
      &:not(:last-child) {
        margin-bottom: 0.48rem;
      }
    }
  }
}

.process {
  width: 100%;
  margin-top: 0.8rem;
  .flex-type(center, column);

  &__title {
    width: 100%;
    padding: 0 0.54rem;
    font-size: 0.36rem;
    font-weight: 700;
    line-height: 0.5rem;
    color: @text-color;
  }

  &__img {
    margin-top: 0.48rem;
    max-width: 6.86rem !important;
  }
}

.modal-content {
  width: 5.58rem;
  font-size: 0.28rem;
  line-height: 0.48rem;

  & > span {
    color: rgba(76, 122, 226, 1);
  }
}

@primary-color: rgba(204, 140, 75, 1);