@import '../../assets/styles/abstracts/mixins.less';
@import '../../assets/styles/abstracts/function.less';
@import '../../assets/styles/abstracts/variables.less';
@import '../../assets/styles/abstracts/reset.less';
@import '../../assets/styles/abstracts/global.less';
.mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  .set-size(100%);
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  .flex-type(center, column);
}

.img {
  max-width: 4rem !important;
  max-height: 100vh;
}

.tips {
  width: 4.8rem;
  margin-top: 0.64rem;
  font-size: 0.28rem;
  color: rgba(167, 167, 167, 1);
  line-height: 0.56rem;
}

@primary-color: rgba(204, 140, 75, 1);