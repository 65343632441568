@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: @white-color;
  .set-size(100%, 0.86rem);
  .flex-type(center);

  &__content {
    .set-size(12rem, 100%);
    .flex-type(space-between);
  }

  &__logo {
    cursor: pointer;
    .set-size(2.76rem, 0.6rem);
  }

  &__menu {
    font-size: 0.18rem;
    margin-right: -20px;
    line-height: 0.86rem;
    color: @text-color;
    .set-size(7.2rem, 0.86rem);
    .flex-type(space-between);
  }

  &__instance {
    .set-size(100%, 0.86rem);
  }
}

:global {
  // 去掉底部边框
  .ant-menu-horizontal {
    border-bottom: 0;
  }

  // 更改选中和预选中的下划线样式
  .ant-menu-item-selected::after,
  .ant-menu-submenu-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 0.05rem solid rgba(204, 140, 75, 1) !important;
  }

  // 更改离开时下划线样式
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: 0.05rem solid transparent;
  }

  // 修改菜单样式
  .ant-menu-submenu-popup > .ant-menu {
    background-color: rgba(255, 255, 255, 0.68);
    backdrop-filter: blur(0.08rem);
  }

  // 子菜单选中样式
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(204, 140, 75, 0.64);
    color: @text-color;
  }

  // 不删除字符串之间的连续空格
  .ant-menu-title-content {
    white-space: pre;
  }
}

@primary-color: rgba(204, 140, 75, 1);