@import '../../assets/styles/abstracts/mixins.less';
@import '../../assets/styles/abstracts/function.less';
@import '../../assets/styles/abstracts/variables.less';
@import '../../assets/styles/abstracts/reset.less';
@import '../../assets/styles/abstracts/global.less';
@font-face {
  font-family: 'iconfont'; /* Project id 2815393 */
  src: url('//at.alicdn.com/t/c/font_2815393_j3m3xtajsum.woff2?t=1663318840268') format('woff2'),
    url('//at.alicdn.com/t/c/font_2815393_j3m3xtajsum.woff?t=1663318840268') format('woff'),
    url('//at.alicdn.com/t/c/font_2815393_j3m3xtajsum.ttf?t=1663318840268') format('truetype');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  line-height: 1;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-playing-1:before {
  content: '\e60e';
}

.icon-playing-2:before {
  content: '\e60d';
}

.icon-playing-3:before {
  content: '\e60c';
}

.icon-audio-bg:before {
  content: '\e609';
}

.icon-recording:before {
  content: '\e608';
}

.icon-playing:before {
  content: '\e607';
}

.icon-visible:before {
  content: '\e6f4';
}

.icon-invisible:before {
  content: '\e6f5';
}

.icon-home_fill:before {
  content: '\e668';
}

.icon-home_line:before {
  content: '\e66a';
}

.icon-arrow-down:before {
  content: '\e6ee';
}

.icon-arrow-left:before {
  content: '\e6ef';
}

.icon-arrow-right:before {
  content: '\e6f0';
}

.icon-arrow-up:before {
  content: '\e6f1';
}

.icon-arrow-up_fill:before {
  content: '\e6f2';
}

.icon-arrow-down_fill:before {
  content: '\e6f3';
}

.icon-satisfied_fill:before {
  content: '\e666';
}

.icon-satisfied_line:before {
  content: '\e667';
}

.icon-service_fill:before {
  content: '\e68a';
}

.icon-service_line:before {
  content: '\e68b';
}

.icon-picture_fill:before {
  content: '\e694';
}

.icon-picture_line:before {
  content: '\e695';
}

.icon-love_fill:before {
  content: '\e6c0';
}

.icon-love_line:before {
  content: '\e6c1';
}

.icon-positioning_fill:before {
  content: '\e6c8';
}

.icon-positioning_line:before {
  content: '\e6c9';
}

.icon-share_line:before {
  content: '\e6ca';
}

.icon-share_fill:before {
  content: '\e6cb';
}

.icon-instructions_fill:before {
  content: '\e664';
}

.icon-instructions_line:before {
  content: '\e665';
}

.icon-succeed_line:before {
  content: '\e66b';
}

.icon-error_line:before {
  content: '\e662';
}

.icon-about_line:before {
  content: '\e66e';
}

.icon-delete_fill:before {
  content: '\e6aa';
}

.icon-photograph_line:before {
  content: '\e6b4';
}

.icon-telephone_fill:before {
  content: '\e6b5';
}

.icon-telephone_line:before {
  content: '\e6b7';
}

.icon-video_line:before {
  content: '\e6b9';
}

.icon-trumpet_line:before {
  content: '\e6ce';
}

.icon-trumpet_fill:before {
  content: '\e6cf';
}

.icon-praise_fill:before {
  content: '\e6e0';
}

.icon-praise_line:before {
  content: '\e6e1';
}

.icon-star_fill:before {
  content: '\e6ea';
}

.icon-star_line:before {
  content: '\e6eb';
}

.icon-succeed_fill:before {
  content: '\e669';
}

.icon-error_fill:before {
  content: '\e660';
}

.icon-about_fill:before {
  content: '\e66f';
}

.icon-delete_line:before {
  content: '\e6a8';
}

.icon-photograph_fill:before {
  content: '\e6b2';
}

.icon-video_fill:before {
  content: '\e6b6';
}

.icon-select:before {
  content: '\e6c5';
}

@primary-color: rgba(204, 140, 75, 1);