@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.layout {
  position: relative;
  min-height: 100vh;
  min-width: 1200px;
  padding-bottom: 2.28rem;
  font-family: PingFangSC-Regular, PingFang SC;

  &__content {
    min-height: calc(100vh - 3.14rem);
    background: rgba(240, 242, 245, 1);
  }

  &__back-top {
    right: calc((100vw - 1200px) / 2 - 0.6rem) !important;
    z-index: 999;

    &--fixed {
      position: fixed !important;
      bottom: 0.6rem !important;
    }

    &--absolute {
      position: absolute !important;
      bottom: 2.68rem;
    }
  }
}

:global(::-webkit-scrollbar) {
  display: none;
}

:global {
  .ant-btn-primary {
    border: 0 !important;
  }

  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    color: @white-color !important;
    background: linear-gradient(
      90deg,
      rgba(252, 189, 109, 1) 0%,
      rgba(147, 85, 52, 1) 100%
    ) !important;
  }

  .ant-image-preview-operations-operation:nth-of-type(n + 5) {
    display: none !important;
  }

  .ant-message {
    z-index: 10000 !important;
  }

  .ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }

  .ant-image-preview-img {
    max-width: 1000px !important;
    width: 1000px;
    height: auto;
  }

  .ant-image-preview-switch-right {
    right: calc((100vw - 1000px) / 2 - 74px) !important;

    & > .anticon {
      font-size: 40px !important;
    }
  }

  .ant-image-preview-switch-left {
    left: calc((100vw - 1000px) / 2 - 74px) !important;

    & > .anticon {
      font-size: 40px !important;
    }
  }
}

@primary-color: rgba(204, 140, 75, 1);