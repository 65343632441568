@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
.mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  .set-size(100%);
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  .flex-type(center, column);
  .set-size(100%);
}

.progress {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 4.8rem;
  font-size: 0.28rem;
  color: @white-color;
  line-height: 0.56rem;
}

.swiper {
  max-width: 100vw;
  max-height: 80vh;

  &__img {
    max-width: 100%;
    // max-height: 80%;
    height: auto;
    object-fit: contain;
  }
}

@primary-color: rgba(204, 140, 75, 1);