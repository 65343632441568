@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
.interview-list {
  width: 100%;
  padding: 0.48rem 0.32rem;
  background-color: @white-color;

  &__item {
    margin-bottom: 0.48rem;
    border: 0;
    border-bottom: 1px solid#e5e5e5;
  }
}

@primary-color: rgba(204, 140, 75, 1);