@import '../../../../../assets/styles/abstracts/mixins.less';
@import '../../../../../assets/styles/abstracts/function.less';
@import '../../../../../assets/styles/abstracts/variables.less';
@import '../../../../../assets/styles/abstracts/reset.less';
@import '../../../../../assets/styles/abstracts/global.less';
@title-color: rgba(224, 178, 132, 1);

.summit {
  padding-bottom: 1.32rem;
  background: rgba(0, 0, 0, 1);
  .flex-type(center, column);
}

.subtitle {
  font-size: 0.36rem;
  font-weight: 700;
  color: @primary-color;
  line-height: 0.5rem;
}

.pv {
  position: relative;
  .set-size(100%, 7.1rem);

  &__img {
    .set-size(100%);
  }

  &__icon {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    cursor: pointer;
    .set-size(0.8rem);
  }

  &__detail {
    padding: 0.6rem 0.54rem 0;
    .set-size(100%);
    font-size: 0.28rem;
    color: rgba(255, 255, 255, 1);
    line-height: 0.56rem;
  }
}

.tag {
  width: 100%;
  padding: 0 0.16rem;
  margin-top: 0.7rem;
  .flex-type(space-between);

  &__card {
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 0.1rem;
    border-radius: 0.07rem;
    font-size: 0.24rem;
    color: @primary-color;
    line-height: 0.48rem;
    z-index: 0;
    .set-size(2.38rem, 1.67rem);

    &__card:not(:last-child) {
      margin-right: 0.02rem;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background: linear-gradient(180deg, @primary-color 0%, rgba(232, 192, 130, 0) 100%);
      border-radius: 0.07rem;
      opacity: 0.38;
      content: '';
      .set-size(100%);
    }
  }

  &__label {
    font-size: 0.36rem;
    font-weight: 700;
    line-height: 0.48rem;
  }
}

.cup {
  width: 100%;
  margin-top: 0.46rem;
  .flex-type(center, column);

  &__bg {
    position: relative;
    z-index: 1;
    width: 100%;
    margin: 0.48rem 0;
    .flex-type(center);

    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      z-index: -1;
      transform: translateY(-50%);
      background-color: rgba(255, 198, 145, 0.45);
      content: '';
      .set-size(7.5rem, 0.54rem);
    }

    &__img {
      z-index: 1;
      .set-size(3.79rem, 4.4rem);
    }
  }

  &__info {
    width: 6.42rem;
    font-size: 0.28rem;
    color: @white-color;
    line-height: 0.56rem;
  }
}

.theme {
  margin-top: 0.24rem;
  .flex-type(center, column);

  &__img {
    max-width: 100vw;
    width: 100%;
  }

  &__content {
    margin-top: -0.78rem;
    width: 6.42rem;
    font-size: 0.28rem;
    font-weight: 400;
    color: @white-color;
    line-height: 0.56rem;
  }
}

.moment {
  margin-top: 0.8rem;
  .flex-type(center, column);

  &__info {
    width: 6.42rem;
    margin-top: 0.48rem;
    font-size: 0.28rem;
    color: @white-color;
    line-height: 0.56rem;
  }
}

.winner {
  box-sizing: border-box;
  width: 100%;
  margin-top: 1.38rem;
  font-size: 0.28rem;
  color: @white-color;
  line-height: 0.56rem;

  &__item {
    padding: 0.48rem 0.52rem;

    &:nth-child(1) {
      background: linear-gradient(180deg, rgba(9, 199, 108, 0.28) 0%, rgba(3, 103, 15, 0.27) 100%),
        left -0.8rem / 7.5rem 13.38rem url('https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/mobile/winner-bg.png');
    }

    &:nth-child(3) {
      background: linear-gradient(180deg, rgba(249, 97, 97, 0.38) 0%, rgba(241, 51, 51, 0.17) 100%),
        left -0.8rem / 7.5rem 13.38rem url('https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/mobile/winner-bg.png');
    }

    &__img {
      margin-bottom: 0.46rem;
      .flex-type();

      &__ins {
        margin-right: 0.24rem;
        .set-size(1.88rem, 4.4rem);

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    &__words {
      width: 100%;

      &__para {
        margin-bottom: 0.56rem;
      }

      &__para:nth-child(2) {
        margin-top: 0.28rem;
      }

      &__para:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.judges {
  width: 100%;
  position: relative;
  padding-top: 0.48rem;
  margin-top: 0.8rem;
  z-index: 0;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(180deg, rgba(236, 158, 46, 1) 0%, rgba(111, 71, 10, 1) 100%);
    opacity: 0.38;
    content: '';
    .set-size(100%);
  }

  &__container {
    z-index: 1;
    .flex-type(center, column);
  }

  &__subtitle {
    margin: 0.8rem 0 0.48rem 0;
    font-size: 0.28rem;
    font-weight: 700;
    color: @primary-color;
    line-height: 0.4rem;
  }

  &__list {
    flex-wrap: wrap;
    .flex-type(center);

    &__item {
      width: 2.2rem;
      margin-bottom: 0.64rem;
    }
  }
}

.specimens {
  padding-left: 0.32rem;
  margin-top: 0.8rem;
  .flex-type(center, column);

  &__swiper {
    width: 7.18rem;
    margin-top: 0.48rem;
  }

  &__img {
    .set-size(100%, 4.26rem);
  }
}

.news {
  margin-top: 0.8rem;
  .flex-type(center, column);

  &__list {
    margin-top: 0.82rem;

    &__item {
      background: transparent;

      &:nth-child(n + 2) {
        margin-top: 0.32rem;
      }
    }
  }

  &__more {
    margin-top: 0.44rem;
    font-size: 0.26rem;
    color: rgba(138, 138, 138, 1);
    line-height: 0.56rem;
    .flex-type(center, column);
  }
}

.structure {
  width: 100%;
  margin-top: 0.8rem;
  padding-bottom: 0.8rem;
  background: left 0.6rem / 7.5rem 8.46rem
    url('https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/mobile/structure-bg.png');
  .flex-type(center, column);

  &__box {
    position: relative;
    width: 100%;
    z-index: 0;
    padding: 0.31rem 0;
    margin-top: 0.38rem;
    .flex-type(center);

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background: rgba(217, 217, 217, 1);
      opacity: 0.18;
      content: '';
      .set-size(100%);
    }
  }

  &__content {
    width: 100%;
    padding: 0 0.32rem;
    margin-top: 0.6rem;
  }

  &__item {
    font-size: 0.32rem;
    color: @primary-color;
    line-height: 0.48rem;

    &:not(:last-child) {
      margin-bottom: 0.48rem;
    }

    &__list {
      .flex-type();
      flex-wrap: wrap;
    }

    &__organ {
      font-size: 0.28rem;
      color: rgba(217, 217, 217, 1);

      &:not(:last-child) {
        margin-right: 0.3rem;
      }
    }
  }
}

.cooperator {
  z-index: 1;
  margin-top: 0.8rem;
  .flex-type(center, column);

  &__img {
    margin-top: 0.48rem;
    .set-size(6.86rem, 5.44rem);

    & > img {
      .set-size(6.86rem, 5.44rem);
    }
  }
}

.video {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
  .set-size(100%);
  .flex-type(center);

  &__container {
    position: relative;
    box-sizing: border-box;

    .flex-type(center);

    @media screen and (orientation: portrait) {
      width: 100%;
    }

    @media screen and (orientation: landscape) {
      height: 100%;
      width: 100%;
    }
  }

  &__ins {
    @media screen and (orientation: portrait) {
      width: 100vw;
    }

    @media screen and (orientation: landscape) {
      width: calc(100vh / 9 * 16) !important;
    }
  }

  &__cancel {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    font-size: 0.52rem;
    color: rgba(151, 151, 151, 1);
  }
}

.year-2023 {
  background: #030624;

  .structure__box:after {
    background: transparent;
  }

  .index_winner__item--even {
    background: rgba(255, 198, 145, 0.2);
  }
}

@primary-color: rgba(204, 140, 75, 1);