@import '../../../assets/styles/abstracts/mixins.less';
@import '../../../assets/styles/abstracts/function.less';
@import '../../../assets/styles/abstracts/variables.less';
@import '../../../assets/styles/abstracts/reset.less';
@import '../../../assets/styles/abstracts/global.less';
@active-color: rgba(210, 153, 84, 1);

.tab {
  position: absolute;
  background: @white-color;
  font-size: 0.24rem;
  color: @text-color;
  line-height: 1rem;
  z-index: 1999;
  .set-size(100%, 1.08rem);
  .flex-type(center);

  &--fixed {
    position: fixed !important;
    top: 0;
    left: 0;
  }

  &__item {
    position: relative;

    &--active {
      color: @active-color;
    }
  }
}

.instance {
  .set-size(100%, 1.08rem);
}

@primary-color: rgba(204, 140, 75, 1);